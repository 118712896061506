import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../_metronic/helpers'


const Integration = () => {

  return (
    <>
      <div className='row g-6 g-xl-9'>
        <div className='col-md-6 col-xxl-3'>
          <div className='card'>
            <div className='card-body d-flex flex-center flex-column p-9'>
              <div className='mb-5'>
                <div className='symbol symbol-75px'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/avatars/gmail.svg')} />
                </div>
              </div>
              <a className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                Email
              </a>
              <div className='fw-bold text-gray-400 mb-6'>In-active</div>
              {/* <Link to='/apps/email'> */}
              <button className='btn btn-sm btn-primary fw-bolder' disabled>
                Coming Soon
              </button>
              {/* </Link> */}
            </div>
          </div>
        </div>

        <div className='col-md-6 col-xxl-3'>
          <div className='card'>
            <div className='card-body d-flex flex-center flex-column p-9'>
              <div className='mb-5'>
                <div className='symbol symbol-75px'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/avatars/sms.png')} />
                </div>
              </div>
              <a className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                SMS
              </a>
              <div className='fw-bold text-gray-400 mb-6'>In-active</div>
              {/* <Link to='/apps/sms'> */}
              <button className='btn btn-sm btn-primary fw-bolder' disabled>
                Coming Soon
              </button>
              {/* </Link> */}
            </div>
          </div>
        </div>

        <div className='col-md-6 col-xxl-3'>
          <div className='card'>
            <div className='card-body d-flex flex-center flex-column p-9'>
              <div className='mb-5'>
                <div className='symbol symbol-75px'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/avatars/razorpay.png')} />
                </div>
              </div>
              <a className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                Razorpay
              </a>
              <div className='fw-bold text-gray-400 mb-6'>Active</div>
              <Link to='/apps/razorpay'>
                <button className='btn btn-sm btn-primary fw-bolder'>
                  Configure
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className='col-md-6 col-xxl-3'>
          {/* <div className='card'>
            <div className='card-body d-flex flex-center flex-column p-9'>
              <div className='mb-5'>
                <div className='symbol symbol-75px'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/avatars/woocommerce.svg')} />
                </div>
              </div>
              <a className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                Woocommerce
              </a>
              <div className='fw-bold text-gray-400 mb-6'>In-active</div>
              <Link to='/apps/woocommerce'>
                <button className='btn btn-sm btn-primary fw-bolder'>
                  Configure
                </button>
              </Link>
            </div>
          </div> */}
        </div>

      </div>
    </>
  )
}
export default Integration








