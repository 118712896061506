import React, { useEffect, useState } from 'react'
import { Form, Formik, FormikValues, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import { Wp_config_post_data, Addons, Wp_setting_post_data, Wp_config_upd_data, Razorpay_config_post_data, Razorpay_config_upd_data } from '../modules/auth/core/_requests'
import Swal from 'sweetalert2';
import { KTIcon } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'


interface SMSdata {
    addon_id: string;
    addon_type: string; 
    razorpay_key_id: string;
    razorpay_key_secret: string; 
    addon_created_on: string;
    addon_status: string;
}

const Razorpay = () => {
    const addon_type = "razorpay"
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false); 
    const [addons, setAddons] = useState<SMSdata[]>([]); 
    const [initialEditValues, setInitialEditValues] = useState(
        {
            addon_id: '',
            razorpay_key_id_upd: '',
            razorpay_key_secret_upd: '', 
        }
    )

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const initialValues = {
        razorpay_key_id: '',
        razorpay_key_secret: '', 
    }

    const validationSchema = Yup.object().shape({
        razorpay_key_id: Yup.string().required('Key ID is required'),
        razorpay_key_secret: Yup.string().required('Key Secret ID is required'), 
    });

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const response = await Razorpay_config_post_data(
                values.razorpay_key_id,
                values.razorpay_key_secret, 
                addon_type,
            );
            actions.resetForm();
            ADDON_GET("razorpay");
            setTimeout(() => {
                setLoading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Submitted',
                    showConfirmButton: true,
                    timer: 3000
                });
            }, 500);
            setShowModal(false)
        }
        catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    const ADDON_GET = async (addon: any) => { 
        try {
            const response = await Addons(addon);
            setAddons(response.data.addon_details);
            if (response.data.addon_details) {
                setIsLoading(false);
                const initialData = response.data.addon_details
                const addonMetaData = JSON.parse(initialData[0].addon_meta_data); 
                setInitialEditValues({
                    addon_id: initialData.length > 0 ? initialData[0].addon_id : '',
                    razorpay_key_id_upd: addonMetaData.razorpay_key_id,
                    razorpay_key_secret_upd: addonMetaData.razorpay_key_secret, 
                }) 
            } 
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        ADDON_GET("razorpay");
    }, []);

    const handleSubmitUpdate = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const { data: auth } = await Razorpay_config_upd_data(
                values.addon_id,
                values.razorpay_key_id_upd,
                values.razorpay_key_secret_upd, 
                addon_type,
            );
            actions.resetForm();
            ADDON_GET("razorpay");
            setTimeout(() => {
                setLoading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Updated',
                    showConfirmButton: true,
                    timer: 3000
                });
            }, 500);
            setShowModal(false)
        }
        catch (error) {
            console.error('Error submitting form data:', error);
        }
    } 

    return (
        <>
        <Link to='/integration'>
                <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                </button>
            </Link>
            <div className="row">
                <div className='col-md-8 offset-md-2 card p-8'>
                    <h4 className='mb-5'>Razorpay Configuration</h4>
                    <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_4"
                            >
                                Configuration
                            </a>
                        </li> 
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="kt_tab_pane_4" role="tabpanel">
                            {isLoading ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div> 
                            ) : (addons.length > 0 ? (
                                <>
                                    {showModal ? (
                                        <div>
                                            <Formik initialValues={initialEditValues} onSubmit={handleSubmitUpdate}>
                                                {() => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <Field type="hidden" name="addon_id" className="form-control" />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="razorpay_key_id_upd" className='form-label mt-3 mb-3'>Key ID</label>
                                                            <Field type="text" name="razorpay_key_id_upd" className="form-control" />
                                                            <ErrorMessage name="razorpay_key_id_upd" component="div" className="text-danger" />
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="form-label mt-3 mb-3" htmlFor="razorpay_key_secret_upd" >Key Secret</label>
                                                            <Field type="text" name="razorpay_key_secret_upd" className="form-control" />
                                                            <ErrorMessage name="razorpay_key_secret_upd" component="div" className="text-danger" />
                                                        </div> 

                                                        <div className='d-flex justify-content-end mt-5'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    ) : (
                                        <>
                                            <div className='d-flex justify-content-between align-items-center mt-10'>
                                                <div className="mb-10">
                                                    <h5 className='fw-bolder'>Configuration Details</h5>
                                                </div>
                                                <div>
                                                    <button className='btn btn-sm btn-primary' onClick={handleButtonClick}>
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='row mb-7'>
                                                <label className='col-lg-4 fw-bold text-muted'>Key ID</label>
                                                <div className='col-lg-8'>
                                                    <span className='fw-bolder fs-6 text-dark'>{initialEditValues.razorpay_key_id_upd}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-4 fw-bold text-muted">Key Secret</label>
                                                <div className="col-lg-8">
                                                    <div className="password-field">
                                                        <span className="fw-bolder fs-6 text-dark me-5">
                                                            {initialEditValues.razorpay_key_secret_upd}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div> 
                                        </>
                                    )}
                                </>
                            ) :
                                <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                                    {() => (
                                        <Form>
                                            <div className="form-group">
                                                <label htmlFor="razorpay_key_id" className='form-label mt-3 mb-3'>Key ID</label>
                                                <Field type="text" name="razorpay_key_id" className="form-control" />
                                                <ErrorMessage name="razorpay_key_id" component="div" className="text-danger" />
                                            </div>

                                            <div className="form-group">
                                                <label className="form-label mt-3 mb-3" htmlFor="razorpay_key_secret" >Key Secret</label>
                                                <Field type="text" name="razorpay_key_secret" className="form-control" />
                                                <ErrorMessage name="razorpay_key_secret" component="div" className="text-danger" />
                                            </div> 

                                            <div className='d-flex justify-content-end mt-5'>
                                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                                    {!loading && 'Submit'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </div> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default Razorpay