import React, { useEffect, useState } from 'react'
import { Form, Formik, ErrorMessage, Field, FormikValues } from 'formik'
import * as Yup from 'yup'
import { KTIcon,toAbsoluteUrl } from '../../_metronic/helpers'
import { SMS_config_post_data, Addons, SMS_setting_post_data } from '../modules/auth/core/_requests'
import Swal from 'sweetalert2';



interface SMSdata {
    addon_id: string;
    addon_user_name: string;
    addon_password: string;
    addon_token: string;
    addon_website_url: string;
    addon_sender_id: string;
    addon_created_on: string;
    addon_status: string;
}
const Woocommerce = () => {
    const addon_type = "sms_addon"
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [addons, setAddons] = useState<SMSdata[]>([]);
    const [initialEditValues, setInitialEditValues] = useState(
        {
            // addon_id: '',
            addon_user_name: 'test',
            addon_password: '',
            addon_website_url: '',
            addon_sender_id: '',
        }
    )

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const initialValues = {
        sms_user_name: '',
        sms_password: '',
        sms_sender_id: '',
        sms_website_url: '',
    }

    const validationSchema = Yup.object().shape({
        sms_user_name: Yup.string().required('User Name is required'),
        sms_password: Yup.string().required('Password is required'),
        sms_sender_id: Yup.string().required('SenderId is required'),
        sms_website_url: Yup.string().required('Website URL is required'),
    });

    // const handleSubmit = async (values: any, actions: FormikValues) => {
    //     console.log(values)
    //     setLoading(true)
    //     try {
    //         const postData = new FormData();
    //         const { data: auth } = await SMS_config_post_data(
    //             postData,
    //             values.sms_user_name,
    //             values.sms_password,
    //             values.sms_sender_id,
    //             values.sms_website_url,
    //            // addon_type
    //         );
    //         // actions.resetForm();
    //         setTimeout(() => {
    //             setLoading(false)
    //             Swal.fire({
    //                 position: 'center',
    //                 icon: 'success',
    //                 title: 'Successfully Submitted',
    //                 showConfirmButton: true,
    //                 timer: 3000
    //             });
    //         }, 500); // 5000 milliseconds = 5 seconds
    //     }
    //     catch (error) {
    //         console.error('Error submitting form data:', error);
    //     }
    // }

    const SMS_ADDON_GET = async (addon: any) => {
        try {
            const response = await Addons(addon);
            setAddons(response.data.addon_details);
            const initialData = response.data.addon_details
            console.log(initialData[0].addon_user_name, 'ini')
            setInitialEditValues({
                addon_user_name: initialData.length > 0 ? initialData[0].addon_user_name : '',
                addon_password: initialData.length > 0 ? initialData[0].addon_password : '',
                addon_website_url: initialData.length > 0 ? initialData[0].addon_website_url : '',
                addon_sender_id: initialData.length > 0 ? initialData[0].addon_sender_id : '',
            })
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        SMS_ADDON_GET("sms_addon");
    }, []);

    // const initialValues = {
    //     customer_id: '',
    //     customer_secret: '',
    //     website_address: '',
    // }

    // const validationSchema = Yup.object().shape({
    //     customer_id: Yup.string().required('Customer Id is required'),
    //     customer_secret: Yup.string().required('Customer Secret is required'),
    //     website_address: Yup.string().required('Website Address is required'),
    // });

    // const handleSubmit = (values: any) => {
    //     console.log(values)
    // }

    return (
        <>
            <div className="row">
                <div className='col-md-8 offset-md-2 card p-8'>
                    <h4 className='mb-5'>SMS Configuration</h4>
                    {/* <h3 className='fw-bolder mb-10'>Woocommerce Configuration</h3>
                    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                        {() => (
                            <Form>
                                <div className="form-group">
                                    <label htmlFor="customer_id" className='form-label mb-3'>Customer Id</label>
                                    <Field type="text" name="customer_id" className="form-control" />
                                    <ErrorMessage name="customer_id" component="div" className="text-danger" />
                                </div>

                                <div className="form-group">
                                    <label className="form-label mt-3 mb-3" htmlFor="customer_secret" >Customer Secret</label>
                                    <Field type="text" name="customer_secret" className="form-control" />
                                    <ErrorMessage name="customer_secret" component="div" className="text-danger" />
                                </div>

                                <div className="form-group">
                                    <label className="form-label mt-3 mb-3" htmlFor="website_address" >Website Address</label>
                                    <Field type="text" name="website_address" className="form-control" />
                                    <ErrorMessage name="website_address" component="div" className="text-danger" />
                                </div>
                                <div className='d-flex justify-content-end mt-5'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Submit'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}

                    </Formik> */}

                    <div className={`card`}>
                        <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-3 mb-1'>Configuration</span>
                                {/* <span className='text-muted mt-1 fw-semibold fs-7'>Total {products.length} Product</span> */}
                            </h3>

                            <div className='card-toolbar'>
                                <button className='btn btn-sm btn-primary' onClick={handleButtonClick}>
                                    <KTIcon iconName='plus' className='fs-2' />
                                    Add New
                                </button>
                            </div>

                        </div>
                    </div>
{/*
                    <div className={`modal${showModal ? ' show' : ''}`} role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Create New Product</h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                                        {() => (
                                            <Form>
                                                <div className="form-group">
                                                    <label htmlFor="sms_user_name" className='form-label mt-3 mb-3'>User Name</label>
                                                    <Field type="text" name="sms_user_name" className="form-control" />
                                                    <ErrorMessage name="sms_user_name" component="div" className="text-danger" />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label mt-3 mb-3" htmlFor="sms_password" >Password</label>
                                                    <Field type="text" name="sms_password" className="form-control" />
                                                    <ErrorMessage name="sms_password" component="div" className="text-danger" />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label mt-3 mb-3" htmlFor="sms_sender_id" >Sender Id</label>
                                                    <Field type="text" name="sms_sender_id" className="form-control" />
                                                    <ErrorMessage name="sms_sender_id" component="div" className="text-danger" />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label mt-3 mb-3" htmlFor="sms_website_url" >Website URL</label>
                                                    <Field type="text" name="sms_website_url" className="form-control" />
                                                    <ErrorMessage name="sms_website_url" component="div" className="text-danger" />
                                                </div>


                                                <div className='d-flex justify-content-end mt-5'>
                                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                                        {!loading && 'Submit'}
                                                        {loading && (
                                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                                Please wait...{' '}
                                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (addons.length > 0 ? (
                        <div className='table-responsive'>
                            <table className='table align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted bg-light'>
                                        <th className='ps-4 rounded-start text-center'>User Name</th>
                                        <th className='text-center'>Password</th>
                                        <th className='text-center'>Sender Id</th>
                                        <th className='text-center'>Website URL</th>
                                        <th className=''>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { addons.map((item) => (
                                                <tr>

                                                    <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>
                                                        {item.addon_user_name}
                                                    </td>
                                                    <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>
                                                        {item.addon_password}
                                                    </td>
                                                    <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>
                                                        {item.addon_sender_id}
                                                    </td>
                                                    <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>
                                                        {item.addon_website_url}
                                                    </td>


                                                    <td style={{ paddingTop: "10px" }}>
                                                        <div className="dropdown">
                                                            <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="bi bi-three-dots fs-5"></i>
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                                <li>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        // onClick={() => handleEditArticle(item.product_id)}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    ) :
                        <div className="card card-custom mt-5">
                            <div className="d-flex justify-content-center p-10">
                                <div className="car">
                                    <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                                    <h3 className="text-center">No products found</h3>
                                </div>
                            </div>
                        </div>
                    )}

                    {showEditModal && (
                        <div className="modal" role="dialog" style={{ display: 'block' }}>
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Edit Product</h5>
                                        <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        {loadingEdit ? (
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                            // <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                                            //     {() => (
                                            //         <Form>
                                            //             <div className="form-group">
                                            //                 <label htmlFor="sms_user_name" className='form-label mt-3 mb-3'>User Name</label>
                                            //                 <Field type="text" name="sms_user_name" className="form-control" />
                                            //                 <ErrorMessage name="sms_user_name" component="div" className="text-danger" />
                                            //             </div>

                                            //             <div className="form-group">
                                            //                 <label className="form-label mt-3 mb-3" htmlFor="sms_password" >Password</label>
                                            //                 <Field type="text" name="sms_password" className="form-control" />
                                            //                 <ErrorMessage name="sms_password" component="div" className="text-danger" />
                                            //             </div>

                                            //             <div className="form-group">
                                            //                 <label className="form-label mt-3 mb-3" htmlFor="sms_sender_id" >Sender Id</label>
                                            //                 <Field type="text" name="sms_sender_id" className="form-control" />
                                            //                 <ErrorMessage name="sms_sender_id" component="div" className="text-danger" />
                                            //             </div>

                                            //             <div className="form-group">
                                            //                 <label className="form-label mt-3 mb-3" htmlFor="sms_website_url" >Website URL</label>
                                            //                 <Field type="text" name="sms_website_url" className="form-control" />
                                            //                 <ErrorMessage name="sms_website_url" component="div" className="text-danger" />
                                            //             </div>


                                            //             <div className='d-flex justify-content-end mt-5'>
                                            //                 <button type='submit' className='btn btn-primary' disabled={loading}>
                                            //                     {!loading && 'Submit'}
                                            //                     {loading && (
                                            //                         <span className='indicator-progress' style={{ display: 'block' }}>
                                            //                             Please wait...{' '}
                                            //                             <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            //                         </span>
                                            //                     )}
                                            //                 </button>
                                            //             </div>
                                            //         </Form>
                                            //     )}
                                            // </Formik>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div >
                    )}
                </div>
            </div>
        </>
    )
}

export default Woocommerce