import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const LeftSide = () => {
    return (
        <>
            <div className='pb-lg-10 p-10 content-align-center w-100 d-none d-lg-block'>
                <img src={toAbsoluteUrl('/media/auth/whatsapp.png')} className='mx-auto mw-100 w-150px w-lg-450px mb-5 d-flex flex-column flex-center' alt='Digital Factory Pondicherry' />
                <p className='text-white fw-bold fs-2qx text-center mb-5'>Fast, Efficient and Productive</p>
                <p className='fs-base text-center fs-5 login_paragraph'>Elevate customer engagement with our dynamic WhatsApp CRM, <br></br>
                streamlining communication for seamless interactions.<br></br>
                 Our high-speed solution optimizes processes, <br></br>boosting productivity and enhancing overall efficiency.</p>

            </div>

            <div className='d-flex flex-column flex-center pb-lg-10 p-10 w-100 d-sm-none d-block'>
                <img src={toAbsoluteUrl('/media/auth/whatsapp.png')} className='mx-auto mw-100 w-300px w-lg-300px mb-10 mt-10' alt='Digital Factory Pondicherry' />
                <h1 className='text-white fs-2qx fw-bold text-center'>Fast, Efficient and Productive</h1>
                <p className='text-white fs-base text-center fw-semibold pb-10'>Elevate customer engagement with our dynamic WhatsApp CRM,
                streamlining communication for seamless interactions.
                 Our high-speed solution optimizes processes,boosting productivity and enhancing overall efficiency.
</p>
            </div>

        </>
    )
}

export default LeftSide