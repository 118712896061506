import React, { useState, useEffect, useRef } from 'react';
import { Customer_GET_data, Customer_POST_data, customerXLData, Customer_EDIT_Details, Customer_UPDATE_data, Customer_DELETE_data, Tags_POST_data, Tags_GET_data } from '../modules/auth/core/_requests'
import { toAbsoluteUrl, KTIcon } from '../../_metronic/helpers'
import { Form, Formik, ErrorMessage, FormikValues, Field } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { useDropzone } from "react-dropzone";
import Select from 'react-select';
import Pagination from './Pagination';
import Loader from './Loader';

interface CustomersData {
    contact_address: string;
    contact_created_on: string;
    contact_id: string;
    contact_mobile: string;
    contact_name: string;
    contact_tags: string[] | string | null | undefined; // Define the type of contact_tags
}

const Customers = () => {
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [fileName, setFileName] = useState('');
    const [uploadFile, setUploadFile] = useState<File | null>(null);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [tagsAdd, setTagsAdd] = useState(false);
    const [newTags, setNewTags] = useState('');
    const [tagsError, setTagsError] = useState('');
    const [tagsSelected, setTagsSelected] = useState<Array<{ value: string; label: string }>>([]);
    const [getTags, setGetTags] = useState<{ tag_id: string; tag_name: string, tag_created_on: string }[]>([]);
    const [customers, setCustomers] = useState<CustomersData[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [serialNumbers, setSerialNumbers] = useState<number[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredMembers, setFilteredMembers] = useState<CustomersData[]>([]);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false)
    const [initialEditValues, setInitialEditValues] = useState(
        {
            contact_id_upd: '',
            customer_name_upd: '',
            customer_phone_upd: '',
            customer_email_upd: '',
            customer_address_upd: '',
        }
    )

    const UserDetails: any = localStorage.getItem('user-details')
    const userDetailsObject = JSON.parse(UserDetails);
    const user_id = userDetailsObject?.user_id
    const user_token = userDetailsObject?.api_token
    const customerId = userDetailsObject?.cust_id

    const handleAddCustomer = () => {
        setOpenModal(true);
        setTagsSelected([]);
    };

    const handleImportCustomer = () => {
        setImportModal(true);
    };

    const initialValues = {
        customer_name: '',
        customer_address: '',
        customer_email: '',
        customer_phone: '',
        country_phone_code: '91',
    }

    const validationSchema = Yup.object().shape({
        customer_name: Yup.string().required('Customer Name is required'),
        customer_email: Yup.string().email('Invalid email'),
        country_phone_code: Yup.string().required('Country code is required'),
        customer_phone: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Phone Number is required'),
    });

    const validationSchemaEdit = Yup.object().shape({
        customer_name_upd: Yup.string().required('Customer Name is required'),
        customer_email_upd: Yup.string().email('Invalid email'),
    });

    const handleTags = (selected: any) => {
        setTagsSelected(selected);
        setTagsAdd(false);
    };

    const FetchTagsData = async (get_all_tags: any, tag_cust_id: any) => {
        try {
            const response = await Tags_GET_data(get_all_tags, tag_cust_id);
            setGetTags(response.data.tag_details);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        FetchTagsData("0", customerId);
    }, []);

    const Category_Add = async (values: any) => {
        try {
            const newCategoryValue = values.tags;
            if (!newCategoryValue) {
                setTagsError('Category name cannot be empty');
                return; // Exit early if the tags is empty
            }

            // Check if the tags already exists in tagsSelected
            const isOptionAlreadySelected = tagsSelected.some(option =>
                option.value.toLowerCase() === newCategoryValue
            );

            if (!isOptionAlreadySelected) {
                // Make the API request to add the new tags
                const { data: auth } = await Tags_POST_data(
                    customerId,
                    newCategoryValue,
                    user_id,
                    user_token,
                );

                // Check if the API request was successful and log the response
                console.log('API Response:', auth);

                if (auth === 'Already Exist') {
                    // Handle the case where the API response indicates that the tags already exists
                    setTagsError('Category already exists. Please select from the dropdown.');
                } else {
                    // Update your component's state with the new tags
                    FetchTagsData("0", customerId);
                    setTagsSelected(prevCategorySelected => [
                        ...prevCategorySelected,
                        { value: newCategoryValue, label: values.tags }
                    ]);
                    setNewTags('');
                    // Clear the error message when a valid value is entered
                    setTagsError('');
                }
            } else {
                // Notify the user that the tags already exists
                setTagsError('Category already exists. Please select from the dropdown.');
            }
        } catch (error) {
            console.error('Error adding tags:', error);
        }
    };

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true);
        try {
            const customerData = new FormData();
            const CustomerNumber = `${values.country_phone_code}${values.customer_phone}`;
            const CategoryValues = tagsSelected.map(option => option.value);
            customerData.append('contact_tags', JSON.stringify(CategoryValues));

            const response = await Customer_POST_data(
                customerData,
                values.customer_name,
                values.customer_email,
                CustomerNumber,
                values.customer_address,
                user_id,
                user_token,
                customerId
            );
            if (response.data.message === "success") {
                actions.resetForm();
                setOpenModal(false);
                setLoading(false);
                setTagsSelected([]);
                fetchContactsList("1", customerId);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Submitted',
                    showConfirmButton: true,
                    timer: 3000
                });
            } else if (response.data.message === "already_exist") {
                setLoading(false);
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Mobile Number Already Exist',
                    showConfirmButton: true,
                    timer: 3000
                });
            } else {
                setLoading(false);
                console.error('Unexpected response data:', response.data);
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };

    const handleEditCustomers = async (contact_id: any, contact_cust_id: any) => {
        setLoadingEdit(true)
        setShowEditModal(true)
        try {
            const response = await Customer_EDIT_Details(contact_id, contact_cust_id);
            setLoadingEdit(false)
            const initialData = response.data.contact_info
            setInitialEditValues(
                {
                    contact_id_upd: initialData.length > 0 ? initialData[0].contact_id : '',
                    customer_name_upd: initialData.length > 0 ? initialData[0].contact_name : '',
                    customer_phone_upd: initialData.length > 0 ? initialData[0].contact_mobile : '',
                    customer_email_upd: initialData.length > 0 ? initialData[0].contact_email : '',
                    customer_address_upd: initialData.length > 0 ? initialData[0].contact_address : '',
                }
            )
            const tagResponse = response.data.contact_info[0].contact_tags;
            const tagData = JSON.parse(tagResponse);
            const formattedArray = tagData.map((item: any) => ({ value: item, label: item }));
            setTagsSelected(formattedArray);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    const handleCustomerUpdate = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const customerData = new FormData();
            const CategoryValues = tagsSelected.map(option => option.value);
            customerData.append('contact_tags_upd', JSON.stringify(CategoryValues));
            const response = await Customer_UPDATE_data(
                customerData,
                values.contact_id_upd,
                values.customer_name_upd,
                values.customer_email_upd,
                values.customer_phone_upd,
                values.customer_address_upd,
                user_id,
                user_token,
                customerId
            )
            if (response.data.message === "success") {
                actions.resetForm();
                setShowEditModal(false);
                setLoading(false);
                setTagsSelected([]);
                fetchContactsList("1", customerId);
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Updated',
                    showConfirmButton: true,
                    timer: 3000
                });
            } else if (response.data.message === "already_used") {
                setLoading(false);
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Mobile Number Already Exist',
                    showConfirmButton: true,
                    timer: 3000
                });
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    const ContactDelete = (contact_del_id: string, contact_cust_id: any) => {
        const handleDeleteEvent = async () => {
            try {
                const response = await Customer_DELETE_data(contact_del_id, contact_cust_id);
                const filterdata = customers.filter(
                    (item) => item.contact_id !== contact_del_id
                );
                setCustomers(filterdata);
            } catch (error) {
                console.error("Error deleting event:", error);
            }
        };
        handleDeleteEvent();
    };

    const handleDeleteConfirmation = (contact_del_id: string, contact_cust_id: any) => {
        Swal.fire({
            title: 'Are you sure!',
            text: "Delete this contact..?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-success', // Success color for "Yes, delete it!"
                cancelButton: 'btn btn-danger',   // Danger color for "No, cancel"
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Call your delete function here
                ContactDelete(contact_del_id, contact_cust_id);
                Swal.fire(
                    'Deleted!',
                    'Your contact has been deleted.',
                    'success'
                )
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Canceling will not save any changes :)',
                    'error'
                )
            }
        });
    };

    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setUploadFile(file);
            setFileName(file.name);
        }
    };

    const handleImportSubmit = async (values: FormikValues) => {
        if (!uploadFile) {
            setShowWarning(true);
            return;
        }
        setLoading(true);
        try {

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                if (!jsonData.length) {
                    // Handle case where jsonData is empty

                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'The uploaded file is missing required headers.',
                        showConfirmButton: true,
                        timer: 5000,
                    });
                    setLoading(false);
                    return;
                }

                // Check if the required headers are present
                const requiredHeaders = ['contact_name', 'contact_mobile', 'contact_email'];

                // Type assertion to specify the type of fileHeaders
                const fileHeaders: string[] = jsonData[0] as string[];

                // Check if all required headers are present in the file
                const headersAreValid = requiredHeaders.every(header => fileHeaders.includes(header));

                if (!headersAreValid) {
                    // Notify the user that the file is missing required headers
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'The uploaded file is missing required headers.',
                        showConfirmButton: true,
                        timer: 5000,
                    });
                    setLoading(false);
                    return;

                }

                // If the headers are valid, proceed with sending the JSON data
                sendJSONData(jsonData);
            };



            reader.readAsArrayBuffer(uploadFile);
        } catch (error) {
            console.error('Error reading file:', error);
            setLoading(false);
        }
    };

    const sendJSONData = async (jsonData: any) => {
        try {
            const formattedData = jsonData.slice(1).map((row: any) => ({
                contact_name: row[0],
                contact_mobile: row[1],
                contact_email: row[2],
                contact_address: row[3],
                contact_tags: row[4],
            }));
            const response = await customerXLData(formattedData, customerId, user_id);
            setLoading(false);
            setImportModal(false)
            setShowWarning(false);
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Successfully Contacts Imported',
                showConfirmButton: true,
                timer: 3000,
            });
            setUploadFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Resetting the value
            }
            setFileName('');
            fetchContactsList("1", customerId);
        } catch (error) {
            console.error('Error submitting JSON data:', error);
            setLoading(false);
        }
    };


    const fetchContactsList = async (get_all_contacts: any, cust_id: any) => {
        try {
            const response = await Customer_GET_data(get_all_contacts, cust_id);
            setCustomers(response.data.contact_details);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        fetchContactsList("1", customerId);
    }, []);

    const itemsPerPage = 10;
    const totalItems = filteredMembers.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentMembers = filteredMembers.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };



    useEffect(() => {
        const filtered = customers?.filter((item: any) => {
            const SearchDetails = `${item.contact_name} ${item.contact_mobile} ${item.contact_tags}`.toLowerCase();
            return SearchDetails.includes(searchQuery.toLowerCase());
        });
        setFilteredMembers(filtered);

        // Update serial numbers based on the filtered members
        const newSerialNumbers = filtered?.map((_, index) => startIndex + index + 1) || [];
        setSerialNumbers(newSerialNumbers);
    }, [searchQuery, customers, startIndex]);

    return (
        <>
            <div className='card'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Customers</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>Total {customers.length} Customers</span>
                    </h3>
                    <div className='card-toolbar'>
                        <div className="d-flex align-items-center position-relative me-5">
                            <div className="input-group">
                                <input type="search" placeholder='Search Name & Phone...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="form-control w-50" />
                                <button type="button" className="btn btn-primary">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div className='me-5'>
                            <button className='btn btn-info' onClick={handleImportCustomer}>
                                <KTIcon iconName='file-down' className='fs-2' />
                                Import
                            </button>
                        </div>
                        <div>
                            <button className='btn btn-primary' onClick={handleAddCustomer}>
                                <KTIcon iconName='plus' className='fs-2' />
                                Add Customers
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    {isLoading ? (
                        <Loader />
                    ) : (currentMembers.length > 0 ? (
                        <div className='table-responsive'>
                            <table className='table align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold fs-6 bg-light'>
                                        <th className='ps-4 rounded-start'>S.No</th>
                                        <th>Name</th>
                                        <th>Number</th>
                                        <th>Address</th>
                                        <th>Tags</th>
                                        <th>Created On</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentMembers.map((item, index) => (
                                        <tr key={index}>
                                            <td className='text-dark fw-bold text-hover-primary fs-6 ps-4'>
                                                {serialNumbers[index]}
                                            </td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                {item.contact_name ? item.contact_name : '-'}
                                            </td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                {item.contact_mobile}
                                            </td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                {item.contact_address ? item.contact_address : '-'}
                                            </td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                {typeof item.contact_tags === 'string' ?
                                                    item.contact_tags.replace(/[\[\]"]+/g, '').split(',').map(tag => (
                                                        <span key={tag} className="badge badge-success me-2 p-1">{tag.trim()}</span>
                                                    ))
                                                    : '-'}
                                            </td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                {item.contact_created_on}
                                            </td>
                                            <td style={{ paddingTop: "10px" }}>
                                                <div className="dropdown">
                                                    <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="bi bi-three-dots fs-5"></i>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={() => handleEditCustomers(item.contact_id, customerId)}
                                                            >
                                                                Edit
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={() => handleDeleteConfirmation(item.contact_id, customerId)}
                                                            >
                                                                Delete
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                        </div>
                    ) :
                        <div className="d-flex justify-content-center p-10">
                            <div className="car">
                                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                                <h3 className="text-center">No customers found</h3>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className={`modal${openModal ? ' show' : ''}`} role="dialog" style={{ display: openModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New Customers :</h5>
                            <button type="button" className="btn-close" onClick={() => setOpenModal(false)} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                                {() => (
                                    <Form>
                                        <div className="form-group">
                                            <label htmlFor="customer_name" className='form-label mb-3 required'>Customer Name</label>
                                            <Field type="text" name="customer_name" className="form-control" />
                                            <ErrorMessage name="customer_name" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3 required" htmlFor="customer_phone" >Phone Number</label>
                                            <div className="input-group">
                                                <Field name="country_phone_code" as="select" className="form-select w-25" id="phone">
                                                    <option value="">select</option>
                                                    <option value="93">Afghanistan +93</option>
                                                    <option value="358">Aland Islands +358</option>
                                                    <option value="355">Albania +355</option>
                                                    <option value="213">Algeria +213</option>
                                                    <option value="1684">American Samoa +1684</option>
                                                    <option value="376">Andorra +376</option>
                                                    <option value="244">Angola +244</option>
                                                    <option value="1264">Anguilla +1264</option>
                                                    <option value="672">Antarctica +672</option>
                                                    <option value="1268">Antigua and Barbuda +1268</option>
                                                    <option value="54">Argentina +54</option>
                                                    <option value="374">Armenia +374</option>
                                                    <option value="297">Aruba +297</option>
                                                    <option value="61">Australia +61</option>
                                                    <option value="43">Austria +43</option>
                                                    <option value="994">Azerbaijan +994</option>
                                                    <option value="1242">Bahamas +1242</option>
                                                    <option value="973">Bahrain +973</option>
                                                    <option value="880">Bangladesh +880</option>
                                                    <option value="1246">Barbados +1246</option>
                                                    <option value="375">Belarus +375</option>
                                                    <option value="32">Belgium +32</option>
                                                    <option value="501">Belize +501</option>
                                                    <option value="229">Benin +229</option>
                                                    <option value="1441">Bermuda +1441</option>
                                                    <option value="975">Bhutan +975</option>
                                                    <option value="591">Bolivia +591</option>
                                                    <option value="599">Bonaire, Sint Eustatius and Saba +599</option>
                                                    <option value="387">Bosnia and Herzegovina +387</option>
                                                    <option value="267">Botswana +267</option>
                                                    <option value="55">Bouvet Island +55</option>
                                                    <option value="55">Brazil +55</option>
                                                    <option value="246">British Indian Ocean Territory +246</option>
                                                    <option value="673">Brunei Darussalam +673</option>
                                                    <option value="359">Bulgaria +359</option>
                                                    <option value="226">Burkina Faso +226</option>
                                                    <option value="257">Burundi +257</option>
                                                    <option value="855">Cambodia +855</option>
                                                    <option value="237">Cameroon +237</option>
                                                    <option value="1">Canada +1</option>
                                                    <option value="238">Cape Verde +238</option>
                                                    <option value="1345">Cayman Islands +1345</option>
                                                    <option value="236">Central African Republic +236</option>
                                                    <option value="235">Chad +235</option>
                                                    <option value="56">Chile +56</option>
                                                    <option value="86">China +86</option>
                                                    <option value="61">Christmas Island +61</option>
                                                    <option value="672">Cocos (Keeling) Islands +672</option>
                                                    <option value="57">Colombia +57</option>
                                                    <option value="269">Comoros +269</option>
                                                    <option value="242">Congo +242</option>
                                                    <option value="242">Congo, Democratic Republic of the Congo +242</option>
                                                    <option value="682">Cook Islands +682</option>
                                                    <option value="506">Costa Rica +506</option>
                                                    <option value="225">Cote D'Ivoire +225</option>
                                                    <option value="385">Croatia +385</option>
                                                    <option value="53">Cuba +53</option>
                                                    <option value="599">Curacao +599</option>
                                                    <option value="357">Cyprus +357</option>
                                                    <option value="420">Czech Republic +420</option>
                                                    <option value="45">Denmark +45</option>
                                                    <option value="253">Djibouti +253</option>
                                                    <option value="1767">Dominica +1767</option>
                                                    <option value="1809">Dominican Republic +1809</option>
                                                    <option value="593">Ecuador +593</option>
                                                    <option value="20">Egypt +20</option>
                                                    <option value="503">El Salvador +503</option>
                                                    <option value="240">Equatorial Guinea +240</option>
                                                    <option value="291">Eritrea +291</option>
                                                    <option value="372">Estonia +372</option>
                                                    <option value="251">Ethiopia +251</option>
                                                    <option value="500">Falkland Islands (Malvinas) +500</option>
                                                    <option value="298">Faroe Islands +298</option>
                                                    <option value="679">Fiji +679</option>
                                                    <option value="358">Finland +358</option>
                                                    <option value="33">France +33</option>
                                                    <option value="594">French Guiana +594</option>
                                                    <option value="689">French Polynesia +689</option>
                                                    <option value="262">French Southern Territories +262</option>
                                                    <option value="241">Gabon +241</option>
                                                    <option value="220">Gambia +220</option>
                                                    <option value="995">Georgia +995</option>
                                                    <option value="49">Germany +49</option>
                                                    <option value="233">Ghana +233</option>
                                                    <option value="350">Gibraltar +350</option>
                                                    <option value="30">Greece +30</option>
                                                    <option value="299">Greenland +299</option>
                                                    <option value="1473">Grenada +1473</option>
                                                    <option value="590">Guadeloupe +590</option>
                                                    <option value="1671">Guam +1671</option>
                                                    <option value="502">Guatemala +502</option>
                                                    <option value="44">Guernsey +44</option>
                                                    <option value="224">Guinea +224</option>
                                                    <option value="245">Guinea-Bissau +245</option>
                                                    <option value="592">Guyana +592</option>
                                                    <option value="509">Haiti +509</option>
                                                    <option value="39">Holy See (Vatican City State) +39</option>
                                                    <option value="504">Honduras +504</option>
                                                    <option value="852">Hong Kong +852</option>
                                                    <option value="36">Hungary +36</option>
                                                    <option value="354">Iceland +354</option>
                                                    <option value="91">India +91</option>
                                                    <option value="62">Indonesia +62</option>
                                                    <option value="98">Iran, Islamic Republic of +98</option>
                                                    <option value="964">Iraq +964</option>
                                                    <option value="353">Ireland +353</option>
                                                    <option value="44">Isle of Man +44</option>
                                                    <option value="972">Israel +972</option>
                                                    <option value="39">Italy +39</option>
                                                    <option value="1876">Jamaica +1876</option>
                                                    <option value="81">Japan +81</option>
                                                    <option value="44">Jersey +44</option>
                                                    <option value="962">Jordan +962</option>
                                                    <option value="7">Kazakhstan +7</option>
                                                    <option value="254">Kenya +254</option>
                                                    <option value="686">Kiribati +686</option>
                                                    <option value="850">Korea, Democratic People's Republic of +850</option>
                                                    <option value="82">Korea, Republic of +82</option>
                                                    <option value="383">Kosovo +383</option>
                                                    <option value="965">Kuwait +965</option>
                                                    <option value="996">Kyrgyzstan +996</option>
                                                    <option value="856">Lao People's Democratic Republic +856</option>
                                                    <option value="371">Latvia +371</option>
                                                    <option value="961">Lebanon +961</option>
                                                    <option value="266">Lesotho +266</option>
                                                    <option value="231">Liberia +231</option>
                                                    <option value="218">Libyan Arab Jamahiriya +218</option>
                                                    <option value="423">Liechtenstein +423</option>
                                                    <option value="370">Lithuania +370</option>
                                                    <option value="352">Luxembourg +352</option>
                                                    <option value="853">Macao +853</option>
                                                    <option value="389">Macedonia, the Former Yugoslav Republic of +389</option>
                                                    <option value="261">Madagascar +261</option>
                                                    <option value="265">Malawi +265</option>
                                                    <option value="60">Malaysia +60</option>
                                                    <option value="960">Maldives +960</option>
                                                    <option value="223">Mali +223</option>
                                                    <option value="356">Malta +356</option>
                                                    <option value="692">Marshall Islands +692</option>
                                                    <option value="596">Martinique +596</option>
                                                    <option value="222">Mauritania +222</option>
                                                    <option value="230">Mauritius +230</option>
                                                    <option value="262">Mayotte +262</option>
                                                    <option value="52">Mexico +52</option>
                                                    <option value="691">Micronesia, Federated States of +691</option>
                                                    <option value="373">Moldova, Republic of +373</option>
                                                    <option value="377">Monaco +377</option>
                                                    <option value="976">Mongolia +976</option>
                                                    <option value="382">Montenegro +382</option>
                                                    <option value="1664">Montserrat +1664</option>
                                                    <option value="212">Morocco +212</option>
                                                    <option value="258">Mozambique +258</option>
                                                    <option value="95">Myanmar +95</option>
                                                    <option value="264">Namibia +264</option>
                                                    <option value="674">Nauru +674</option>
                                                    <option value="977">Nepal +977</option>
                                                    <option value="31">Netherlands +31</option>
                                                    <option value="599">Netherlands Antilles +599</option>
                                                    <option value="687">New Caledonia +687</option>
                                                    <option value="64">New Zealand +64</option>
                                                    <option value="505">Nicaragua +505</option>
                                                    <option value="227">Niger +227</option>
                                                    <option value="234">Nigeria +234</option>
                                                    <option value="683">Niue +683</option>
                                                    <option value="672">Norfolk Island +672</option>
                                                    <option value="1670">Northern Mariana Islands +1670</option>
                                                    <option value="47">Norway +47</option>
                                                    <option value="968">Oman +968</option>
                                                    <option value="92">Pakistan +92</option>
                                                    <option value="680">Palau +680</option>
                                                    <option value="970">Palestinian Territory, Occupied +970</option>
                                                    <option value="507">Panama +507</option>
                                                    <option value="675">Papua New Guinea +675</option>
                                                    <option value="595">Paraguay +595</option>
                                                    <option value="51">Peru +51</option>
                                                    <option value="63">Philippines +63</option>
                                                    <option value="64">Pitcairn +64</option>
                                                    <option value="48">Poland +48</option>
                                                    <option value="351">Portugal +351</option>
                                                    <option value="1787">Puerto Rico +1787</option>
                                                    <option value="974">Qatar +974</option>
                                                    <option value="262">Reunion +262</option>
                                                    <option value="40">Romania +40</option>
                                                    <option value="7">Russian Federation +7</option>
                                                    <option value="250">Rwanda +250</option>
                                                    <option value="590">Saint Barthelemy +590</option>
                                                    <option value="290">Saint Helena +290</option>
                                                    <option value="1869">Saint Kitts and Nevis +1869</option>
                                                    <option value="1758">Saint Lucia +1758</option>
                                                    <option value="590">Saint Martin +590</option>
                                                    <option value="508">Saint Pierre and Miquelon +508</option>
                                                    <option value="1784">Saint Vincent and the Grenadines +1784</option>
                                                    <option value="684">Samoa +684</option>
                                                    <option value="378">San Marino +378</option>
                                                    <option value="239">Sao Tome and Principe +239</option>
                                                    <option value="966">Saudi Arabia +966</option>
                                                    <option value="221">Senegal +221</option>
                                                    <option value="381">Serbia +381</option>
                                                    <option value="381">Serbia and Montenegro +381</option>
                                                    <option value="248">Seychelles +248</option>
                                                    <option value="232">Sierra Leone +232</option>
                                                    <option value="65">Singapore +65</option>
                                                    <option value="721">Sint Maarten +721</option>
                                                    <option value="421">Slovakia +421</option>
                                                    <option value="386">Slovenia +386</option>
                                                    <option value="677">Solomon Islands +677</option>
                                                    <option value="252">Somalia +252</option>
                                                    <option value="27">South Africa +27</option>
                                                    <option value="500">South Georgia and the South Sandwich Islands +500</option>
                                                    <option value="211">South Sudan +211</option>
                                                    <option value="34">Spain +34</option>
                                                    <option value="94">Sri Lanka +94</option>
                                                    <option value="249">Sudan +249</option>
                                                    <option value="597">Suriname +597</option>
                                                    <option value="47">Svalbard and Jan Mayen +47</option>
                                                    <option value="268">Swaziland +268</option>
                                                    <option value="46">Sweden +46</option>
                                                    <option value="41">Switzerland +41</option>
                                                    <option value="963">Syrian Arab Republic +963</option>
                                                    <option value="886">Taiwan, Province of China +886</option>
                                                    <option value="992">Tajikistan +992</option>
                                                    <option value="255">Tanzania, United Republic of +255</option>
                                                    <option value="66">Thailand +66</option>
                                                    <option value="670">Timor-Leste +670</option>
                                                    <option value="228">Togo +228</option>
                                                    <option value="690">Tokelau +690</option>
                                                    <option value="676">Tonga +676</option>
                                                    <option value="1868">Trinidad and Tobago +1868</option>
                                                    <option value="216">Tunisia +216</option>
                                                    <option value="90">Turkey +90</option>
                                                    <option value="7370">Turkmenistan +7370</option>
                                                    <option value="1649">Turks and Caicos Islands +1649</option>
                                                    <option value="688">Tuvalu +688</option>
                                                    <option value="256">Uganda +256</option>
                                                    <option value="380">Ukraine +380</option>
                                                    <option value="971">United Arab Emirates +971</option>
                                                    <option value="44">United Kingdom +44</option>
                                                    <option value="1">United States +1</option>
                                                    <option value="1">United States Minor Outlying Islands +1</option>
                                                    <option value="598">Uruguay +598</option>
                                                    <option value="998">Uzbekistan +998</option>
                                                    <option value="678">Vanuatu +678</option>
                                                    <option value="58">Venezuela +58</option>
                                                    <option value="84">Viet Nam +84</option>
                                                    <option value="1284">Virgin Islands, British +1284</option>
                                                    <option value="1340">Virgin Islands, U.s. +1340</option>
                                                    <option value="681">Wallis and Futuna +681</option>
                                                    <option value="212">Western Sahara +212</option>
                                                    <option value="967">Yemen +967</option>
                                                    <option value="260">Zambia +260</option>
                                                    <option value="263">Zimbabwe +263</option>
                                                </Field>
                                                <Field type="number" name="customer_phone" className="form-control form-control-lg w-75" />
                                            </div>
                                            <ErrorMessage name="country_phone_code" component="div" className="text-danger" />
                                            <ErrorMessage name="customer_phone" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="customer_email" >Email Address</label>
                                            <Field type="text" name="customer_email" className="form-control form-control-lg" />
                                            <ErrorMessage name="customer_email" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <div className='mt-5'>
                                                <label htmlFor="contact_tags" className='form-label mt-3 mb-2'>Tags</label>
                                                <button type='button'
                                                    className={`Plus_icon_btn ${tagsAdd ? 'd-none' : ''}`}
                                                    onClick={() => setTagsAdd(true)} // Set tagsAdd to true when clicked
                                                >
                                                    <i className="bi bi-plus plus_icon" />Add New
                                                </button>
                                                <button type='button'
                                                    className={`Plus_icon_btn ${tagsAdd ? '' : 'd-none'}`}
                                                    onClick={() => {
                                                        setTagsError(''); // Clear the error message
                                                        setTagsAdd(false); // Close the tags add section
                                                    }}
                                                >
                                                    <i className="bi bi-x plus_icon" />Close
                                                </button>
                                            </div>
                                            {tagsAdd && (
                                                <div className='d-flex'>
                                                    <Field
                                                        type="text"
                                                        value={newTags}
                                                        name='tags'
                                                        onChange={(e: any) => {
                                                            setNewTags(e.target.value);
                                                            // Clear the error message when the user starts typing
                                                            setTagsError('');
                                                        }}
                                                        className="form-control mt-5"
                                                        placeholder='Enter New Tags'
                                                    />
                                                    <button
                                                        type='button'
                                                        onClick={() => Category_Add({ tags: newTags })}
                                                        className="btn btn-primary Add_new_cate_btn"
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            )}
                                            {tagsError && <div className="text-danger">{tagsError}</div>}
                                            <Select
                                                isMulti
                                                options={getTags
                                                    .map(item => ({
                                                        value: item.tag_name,
                                                        label: item.tag_name
                                                    }))}
                                                value={tagsSelected}
                                                name='contact_tags'
                                                placeholder='Select Tags'
                                                onChange={(tagsSelected) => {
                                                    handleTags(tagsSelected);
                                                }}
                                                className='w-100  mt-2'
                                            />
                                            <ErrorMessage name="product_category" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="customer_address" >Customer Address</label>
                                            <Field
                                                name="customer_address"
                                                className="form-control"
                                                as="textarea"
                                            />
                                            <ErrorMessage name="customer_address" component="div" className="text-danger" />
                                        </div>

                                        <div className="modal-footer">

                                            <div className='d-flex justify-content-end'>
                                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                                    {!loading && 'Submit'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal${importModal ? ' show' : ''}`} role="dialog" style={{ display: importModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Bulk Import Customers <br></br>
                            <span className='fs-7 fw-normal'> <i className="bi bi-file-earmark-spreadsheet"></i>   Download Sample Format - <a href='https://digitalfactory.co.in/df_whatsapp/sample_contact_import.xlsx' className='text-primary' download>Click Here </a></span></h5>
                            <button type="button" className="btn-close" onClick={() => setImportModal(false)} aria-label="Close"></button>
                        </div>
                        <Formik
                            initialValues={{ file: null }}
                            onSubmit={handleImportSubmit}
                        >
                            {() => (
                                <Form id="bulk-import">
                                    <div className="modal-body">

                                        <div className='p-10 border border-dashed rounded-2 border-primary d-flex justify-content-center w-100' style={{ cursor: 'pointer' }} onClick={() => fileInputRef.current && fileInputRef.current.click()}>
                                            <input type="file" className='w-100' accept=".xlsx, .xls" onChange={handleFileUpload} ref={fileInputRef} style={{ display: 'none' }} />
                                            <span className=''>
                                                {fileName ? (
                                                    fileName
                                                ) : (
                                                    <span>
                                                        <i className="bi bi-upload fs-2 me-2"></i> Click here to upload excel file (.xls,.xlsx)
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                        {showWarning && <div className="text-danger mt-2">Please upload a file.</div>}

                                    </div>
                                    <div className="modal-footer">
                                        <div className='row col-12'>
                                            <button type='submit' className='btn btn-primary btn-block' disabled={loading}>
                                                {!loading && 'Submit'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>


                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>

            {showEditModal && (
                <div className="modal" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Details</h5>
                                <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body" >
                                {loadingEdit ? (
                                    <Loader />
                                ) : (
                                    <>
                                        <Formik initialValues={initialEditValues} validationSchema={validationSchemaEdit} onSubmit={handleCustomerUpdate}>
                                            {() => (
                                                <Form>
                                                    <div className="form-group">
                                                        <input type="hidden" name="contact_id_upd" className="form-control" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="customer_name_upd" className='form-label mb-3 required'>Customer Name</label>
                                                        <Field type="text" name="customer_name_upd" className="form-control" />
                                                        <ErrorMessage name="customer_name_upd" component="div" className="text-danger" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label mt-3 mb-3 required" htmlFor="customer_phone_upd">Phone Number</label>
                                                        <Field type="number" name="customer_phone_upd" className="form-control form-control-lg" readOnly />
                                                        <ErrorMessage name="customer_phone_upd" component="div" className="text-danger" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label mt-3 mb-3" htmlFor="customer_email_upd">Email Address</label>
                                                        <Field type="text" name="customer_email_upd" className="form-control form-control-lg" />
                                                        <ErrorMessage name="customer_email_upd" component="div" className="text-danger" />
                                                    </div>

                                                    <div className="form-group">
                                                        <div className='mt-5'>
                                                            <label htmlFor="contact_tags" className='form-label mt-3 mb-2'>Tags</label>
                                                            <button type='button'
                                                                className={`Plus_icon_btn ${tagsAdd ? 'd-none' : ''}`}
                                                                onClick={() => setTagsAdd(true)} // Set tagsAdd to true when clicked
                                                            >
                                                                <i className="bi bi-plus plus_icon" />Add New
                                                            </button>
                                                            <button type='button'
                                                                className={`Plus_icon_btn ${tagsAdd ? '' : 'd-none'}`}
                                                                onClick={() => {
                                                                    setTagsError(''); // Clear the error message
                                                                    setTagsAdd(false); // Close the tags add section
                                                                }}
                                                            >
                                                                <i className="bi bi-x plus_icon" />Close
                                                            </button>
                                                        </div>
                                                        {tagsAdd && (
                                                            <div className='d-flex'>
                                                                <Field
                                                                    type="text"
                                                                    value={newTags}
                                                                    name='tags'
                                                                    onChange={(e: any) => {
                                                                        setNewTags(e.target.value);
                                                                        // Clear the error message when the user starts typing
                                                                        setTagsError('');
                                                                    }}
                                                                    className="form-control mt-5"
                                                                    placeholder='Enter New Tags'
                                                                />
                                                                <button
                                                                    type='button'
                                                                    onClick={() => Category_Add({ tags: newTags })}
                                                                    className="btn btn-primary Add_new_cate_btn"
                                                                >
                                                                    Add
                                                                </button>
                                                            </div>
                                                        )}
                                                        {tagsError && <div className="text-danger">{tagsError}</div>}
                                                        <Select
                                                            isMulti
                                                            options={getTags
                                                                .map(item => ({
                                                                    value: item.tag_name,
                                                                    label: item.tag_name
                                                                }))}
                                                            value={tagsSelected}
                                                            name='contact_tags'
                                                            placeholder='Select Tags'
                                                            onChange={(tagsSelected) => {
                                                                handleTags(tagsSelected);
                                                            }}
                                                            className='w-100  mt-2'
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="form-label mt-3 mb-3" htmlFor="customer_address_upd" >Customer Address</label>
                                                        <Field
                                                            name="customer_address_upd"
                                                            className="form-control"
                                                            as="textarea"
                                                        />
                                                        <ErrorMessage name="customer_address_upd" component="div" className="text-danger" />
                                                    </div>

                                                    <div className="modal-footer">

                                                        <div className='d-flex justify-content-end'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </>
                                )}
                            </div>
                        </div >
                    </div >
                </div >
            )}

        </>
    )
}

export default Customers