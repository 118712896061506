import { lazy, FC, Suspense, useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import Dashboard from '../pages/dashboard/Dashboard'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import Integration from "../pages/Integration"
import Profile from '../pages/Profile'
import WhatsappConfigure from '../pages/WhatsappConfigure'
import Woocommerce from '../pages/Woocommerce'
import EmailConfigure from '../pages/EmailConfigure'
import SMSConfigure from '../pages/SMSConfigure'
import Orders from '../pages/Orders'
import Customers from '../pages/Customers'
import OrdersReport from '../pages/OrderReport'
import Catalogue from '../pages/Catalogue'
import WhatsappStore from '../pages/WhatsappStore'
import Razorpay from '../pages/Razorpay'
import { UserDeatils_Post } from '../modules/auth/core/_requests'
import SubscriptionAlert from '../modules/subscription/SubscriptionAlert'

interface UserDetails {
  company_name: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  pic: string;
  zipcode: string;
  email: string;
  address_line1: string;
  address_line2: string;
  subscriptions_end: any;
  subscriptions_start: string;
}

const PrivateRoutes = () => {
  const [details, setDetails] = useState<UserDetails>()
  const [isLoading, setIsLoading] = useState(true);
  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const customerId = userDetailsObject?.cust_id
  const user_token = userDetailsObject?.api_token

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const ProductsPage = lazy(() => import('../modules/products/ProductsPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const WhatsappPage = lazy(() => import('../modules/whatsapp/WhatsappPage'))



  const fetchUserDetails = async (user_token: any, customerId: any) => {
    try {
      const response = await UserDeatils_Post(user_token, customerId);
      setDetails(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchUserDetails(user_token, customerId);
  }, []);

  const currentDate = new Date().toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
  const subscriptionEndDateStr: any = details?.subscriptions_end;
  // const newDateStr: any = "31-08-2024";
  function parseDate(dateStr: any) {
    const [day, month, year] = dateStr.split('-');
    return new Date(`${year}-${month}-${day}`);
  }
  let CheckExpiryDate: any
  if (subscriptionEndDateStr && currentDate) {
    const subscriptionEndDate = parseDate(subscriptionEndDateStr);
    const newDate = parseDate(currentDate);
    CheckExpiryDate = newDate <= subscriptionEndDate;
  } else {
    <></>
  }
  return (
    <>
      {isLoading ? (
        <>
          <Routes>
            <Route element={<MasterLayout />}>
              <Route path='auth/*' element={<Navigate to='/dashboard' />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path="/integration" element={<Integration />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/apps/whatsapp" element={<WhatsappConfigure />} />
              <Route path="/apps/catalogue" element={<Catalogue />} />
              <Route path="/apps/whatsapp_store" element={<WhatsappStore />} />
              <Route path="/apps/woocommerce" element={<Woocommerce />} />
              <Route path="/apps/email" element={<EmailConfigure />} />
              <Route path="/apps/sms" element={<SMSConfigure />} />
              <Route path="/apps/razorpay" element={<Razorpay />} />
              <Route path="/order-status/:order_status" element={<Orders />} />
              <Route path="/total-orders" element={<Orders />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/report" element={<OrdersReport />} />
              {/* Lazy Modules */}
              <Route
                path='crafted/pages/profile/*'
                element={
                  <SuspensedView>
                    <ProfilePage />
                  </SuspensedView>
                }
              />
              <Route
                path='crafted/pages/wizards/*'
                element={
                  <SuspensedView>
                    <WizardsPage />
                  </SuspensedView>
                }
              />
              <Route
                path='crafted/widgets/*'
                element={
                  <SuspensedView>
                    <WidgetsPage />
                  </SuspensedView>
                }
              />
              <Route
                path='crafted/account/*'
                element={
                  <SuspensedView>
                    <AccountPage />
                  </SuspensedView>
                }
              />
              <Route
                path='settings/*'
                element={
                  <SuspensedView>
                    <ProductsPage />
                  </SuspensedView>
                }
              />
              <Route
                path='whatsapp/*'
                element={
                  <SuspensedView>
                    <WhatsappPage />
                  </SuspensedView>
                }
              />
              <Route
                path='apps/chat/*'
                element={
                  <SuspensedView>
                    <ChatPage />
                  </SuspensedView>
                }
              />
              <Route
                path='apps/pages/*'
                element={
                  <SuspensedView>
                    <UsersPage />
                  </SuspensedView>
                }
              />
              <Route
                path='apps/pages/*'
                element={
                  <SuspensedView>
                    <Profile />
                  </SuspensedView>
                }
              />


              {/* Page Not Found */}
              <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
          </Routes>
        </>
      ) :
        (CheckExpiryDate == true ?
          (
            <>
              <Routes>
                <Route element={<MasterLayout />}>
                  <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                  <Route path='dashboard' element={<Dashboard />} />
                  <Route path="/integration" element={<Integration />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/apps/whatsapp" element={<WhatsappConfigure />} />
                  <Route path="/apps/catalogue" element={<Catalogue />} />
                  <Route path="/apps/whatsapp_store" element={<WhatsappStore />} />
                  <Route path="/apps/woocommerce" element={<Woocommerce />} />
                  <Route path="/apps/email" element={<EmailConfigure />} />
                  <Route path="/apps/sms" element={<SMSConfigure />} />
                  <Route path="/apps/razorpay" element={<Razorpay />} />
                  <Route path="/order-status/:order_status" element={<Orders />} />
                  <Route path="/total-orders" element={<Orders />} />
                  <Route path="/customers" element={<Customers />} />
                  <Route path="/report" element={<OrdersReport />} />
                  {/* Lazy Modules */}
                  <Route
                    path='crafted/pages/profile/*'
                    element={
                      <SuspensedView>
                        <ProfilePage />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='crafted/pages/wizards/*'
                    element={
                      <SuspensedView>
                        <WizardsPage />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='crafted/widgets/*'
                    element={
                      <SuspensedView>
                        <WidgetsPage />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='crafted/account/*'
                    element={
                      <SuspensedView>
                        <AccountPage />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='settings/*'
                    element={
                      <SuspensedView>
                        <ProductsPage />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='whatsapp/*'
                    element={
                      <SuspensedView>
                        <WhatsappPage />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='apps/chat/*'
                    element={
                      <SuspensedView>
                        <ChatPage />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='apps/pages/*'
                    element={
                      <SuspensedView>
                        <UsersPage />
                      </SuspensedView>
                    }
                  />
                  <Route
                    path='apps/pages/*'
                    element={
                      <SuspensedView>
                        <Profile />
                      </SuspensedView>
                    }
                  />


                  {/* Page Not Found */}
                  <Route path='*' element={<Navigate to='/error/404' />} />
                </Route>
              </Routes>
            </>
          )
          :
          (
            <>
              <SubscriptionAlert details={details} />
            </>
          )
        )
      }
    </>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-success')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
