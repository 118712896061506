/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { useAuth } from '../core/Auth' 
import LeftSide from './LeftSide'

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email')
    .required('Email Address is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password)
        saveAuth(auth)
        const loginStatus = auth.api_token;
        // Assuming 'status' indicates the login status
        // console.log(loginStatus);
        if (loginStatus != undefined) {
          const { data: user } = await getUserByToken(auth.api_token)
          setCurrentUser(user)

        } else {
          saveAuth(undefined)
          setStatus('The login details are incorrect')
          setSubmitting(false)
          setLoading(false)
        }

      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <div className='row me-0'>
        <div className='col-xl-6 login_left_image d-none d-lg-block vh-100'>
          <LeftSide />
        </div>
        <div className='col-xl-6 d-none d-lg-block login_bg'>
          <div className="d-flex flex-column p-10">
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
            >
              <div className="text-center mb-7 mt-20">
                <h1 className="text-dark fw-bolder">
                  Sign In
                </h1>
              </div>
              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Enter Email Address</label>
                <input
                  placeholder='Enter Email Address'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='text'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6'>Enter Your Password</label>
                <input
                  type='password'
                  autoComplete='off'
                  placeholder='Enter Your Password'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div></div>
                <Link to='/auth/forgot-password' className='link-primary'>
                  Forgot Password ?
                </Link>
              </div>
              <div className='d-grid mb-8'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn btn-success'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>  
                 <div className='text-gray-500 text-center fw-semibold fs-6'>
                 Built & Powered by <a href='https://digitalfactoryindia.com/' target='_new'>DIGITAL FACTORY</a>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className='row g-5 g-xl-8 me-0 d-sm-none d-block'>
        <div className='col-xl-6'>
          <div className="d-flex flex-column w-md-600px p-10">
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form_mob'
            >
              <div className="text-center mb-7 mt-20">
                <h1 className="text-dark fw-bolder">
                  Sign In
                </h1>
              </div>
              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Enter Email Address</label>
                <input
                  placeholder='Enter Email Address'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='text'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Enter Your Password</label>
                <input
                  type='password'
                  autoComplete='off'
                  placeholder='Enter Your Password'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div></div>
                <Link to='/auth/forgot-password' className='link-primary'>
                  Forgot Password ?
                </Link>
              </div>
              <div className='d-grid mb-8'>
                <button
                  type='submit'
                  // id='kt_sign_in_submit'
                  className='btn btn-success'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>


              {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
                Not Registered?{' '}
                <Link to='/auth/registration' className='link-primary'>
                  Sign up
                </Link>
              </div> */}
            </form>
          </div>
        </div>
        <div className='col-xl-6  login_left_image'>
          <LeftSide />

          <div className='text-white text-center fw-semibold fs-6 pb-5'>
           Built & Powered by <a href='https://digitalfactoryindia.com/' target='_new' className='text-white'>DIGITAL FACTORY</a>
              </div>
        </div>
      </div>
    </>

  )
}