import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>

      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      /> 
      
      <SidebarMenuItem to="/orders" fontIcon='bi-layers' title="Orders" icon='delivery-time' />
      <SidebarMenuItem to="/customers" fontIcon='bi-layers' title="Contacts" icon='address-book' />
      <SidebarMenuItem to='whatsapp/chat' icon='message-text' title='Conversations' />
      <SidebarMenuItem to='whatsapp/broadcast' icon='notification-bing' title='Broadcast' />
      <SidebarMenuItem to='whatsapp/catalogue' title='Catalogue' icon='note-2' />
      <SidebarMenuItem to='whatsapp/template' title='Template' icon='directbox-default' />
      
      <SidebarMenuItem to="/crafted/account/overview"
        fontIcon='bi-layers'
        title="My Account"
        icon='profile-circle' />

    
      <SidebarMenuItem to="/report"
        fontIcon='filter-edit'
        title="Report"
        icon='filter-edit' />


      <SidebarMenuItem to="/integration"
        fontIcon='bi-layers'
        title="Integration"
        icon='bi bi-link-45deg' />

      <SidebarMenuItemWithSub
        to='crafted/settings/'
        title='Settings'
        icon='setting-2'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='settings/api' title='API' hasBullet={true} />
        {/* <SidebarMenuItem to='settings/category' title='Category' hasBullet={true} /> */}
      </SidebarMenuItemWithSub>

      {/* <SidebarMenuItem to="/coupon"
        fontIcon='bi-layers'
        title="Coupon"
        icon='receipt-square' /> */}
    </>
  )
}

export { SidebarMenuMain }
