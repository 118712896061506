import React from 'react'
import { Link } from 'react-router-dom'
import { KTIcon } from '../../_metronic/helpers'
import { Step1 } from '../modules/wizards/components/steps/Step1'

const Catalogue = () => {
    return (
        <div>
            <Link to='/settings/api'>
                <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                </button>
            </Link>
            <Step1 />
        </div>
    )
}

export default Catalogue