import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import {requestOTP} from '../core/_requests'




const OtpVerification = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const [user_mobile, setUsermobile] = useState(localStorage.getItem('user_mobile')!);
    const [otpValues, setOtpValues] = useState({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' });

    const handleChange = (value1: any, event: any) => {
        setOtpValues({ ...otpValues, [value1]: event.target.value });
    };


    const inputfocus = (elmnt: any) => {
        if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus();
            }
        } else {
            const next = elmnt.target.tabIndex;
            if (next < 6) {
                elmnt.target.form.elements[next].focus();
            }
        }
    };

    const handlePaste = (event: any) => {
        event.preventDefault();
        const pasteData = event.clipboardData.getData('text/plain');

        if (pasteData.length === 6 && /^[0-9]+$/.test(pasteData)) {
            setOtpValues({
                otp1: pasteData[0],
                otp2: pasteData[1],
                otp3: pasteData[2],
                otp4: pasteData[3],
                otp5: pasteData[4],
                otp6: pasteData[5],
            });
        }
    };

    const otpSchema = Yup.object().shape({
        otp: Yup.string()
            .min(6, 'Enter 6 Digits OTP')
            .max(6, 'Enter 6 Digits OTP')
            .required('OTP is required'),
    })

    const initialValues = {
        otp: '',
        user_mobile: ''
    }

    const getConcatenatedOtp = () => {
        const concatenatedOtp = `${otpValues.otp1}${otpValues.otp2}${otpValues.otp3}${otpValues.otp4}${otpValues.otp5}${otpValues.otp6}`;
        console.log('Concatenated OTP:', concatenatedOtp);
        return concatenatedOtp;
    };


    const formik = useFormik({
        initialValues,
        validationSchema: otpSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
          setLoading(true)
          setHasErrors(undefined)
          setTimeout(() => {
            requestOTP(values.otp,user_mobile)
              .then(response => {
                //console.log(response.data)
             const response_status =  (response.data!='Not Validated') ?  (navigate("/auth/password"),(setLoading(false)),setSubmitting(false),setHasErrors(false))
             : (formik.setErrors({otp:"OTP is Invalid"}),(setLoading(false)),setSubmitting(false));
    
              })
           .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The Otp is InCorrect')
           })
          }, 1000)
        },
      })

    
    return (
        <>
            <div className='row g-5 g-xl-8 me-0'>
                <div className='col-xl-7 login_left_image'>
                    <div className='d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100'>
                        <img src={toAbsoluteUrl('/media/auth/agency.png')} className='mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20' alt='Metronic' />
                        <h1 className='text-gray-800 fs-2qx fw-bold text-center mb-7'>Fast, Efficient and Productive</h1>
                        <p className='text-gray-600 fs-base text-center fw-semibold login_paragraph'>In this kind of post, the blogger introduces a person they’ve interviewed
                            and provides some background information about the interviewee and their
                            work following this is a transcript of the interview.</p>
                    </div>
                </div>
                <div className='col-xl-5'>
                    <div className="d-flex flex-column flex-center w-md-600px p-10 login">
                        <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px mb-3">
                            <form
                                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                                noValidate
                                id='kt_login_otp_validate'
                                onSubmit={formik.handleSubmit}
                            >
                                <div className='text-center mb-10'>
                                    {/* begin::Title */}
                                    {/* <h1 className='text-dark fw-bolder mb-3'>OTP</h1> */}
                                    {/* end::Title */}

                                    {/* begin::Link */}
                                    <div className='text-gray-500 fw-semibold fs-6 p-2'>
                                        Please enter the OTP below to verify your Email Address. If you do not find the email in your inbox, make sure you check your spam folder.        </div>
                                    {/* end::Link */}
                                </div>

                                {/* begin::Title */}
                                {hasErrors === true && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>
                                            Sorry, looks like there are some errors detected, please try again.
                                        </div>
                                    </div>
                                )}

                                {hasErrors === false && (
                                    <div className='mb-10 bg-light-success p-5 rounded'>
                                        <div className='text-dark'>Successfully Verified. Please Wait...</div>
                                    </div>
                                )}
                                {/* end::Title */}

                                {/* begin::Form group */}
                                <div className='fv-row mb-8'>
                                    <label className='form-label fw-bolder text-gray-900 fs-6 text-center'>  Enter the 6 digit OTP</label>
                                    <input
                                        type='text'
                                        placeholder=''
                                        autoComplete='off'
                                        maxLength={6}
                                        {...formik.getFieldProps('otp')}
                                        className={clsx(
                                            'form-control bg-transparent',
                                            { 'is-invalid': formik.touched.otp && formik.errors.otp },
                                            {
                                                'is-valid': formik.touched.otp && !formik.errors.otp,
                                            }
                                        )}
                                    />
                                    {formik.touched.otp && formik.errors.otp && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.otp}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <input
                                    type='hidden'
                                    name='user_mobile'
                                    value='323'
                                />
                                {/* end::Form group */}

                                {/* begin::Form group */}
                                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                                    <button
                                        type='submit'
                                        id='kt_password_reset_submit'
                                        className='btn btn-lg btn-primary w-100 mb-5'
                                        disabled={formik.isSubmitting || !formik.isValid}
                                    >
                                        {!loading && <span className='indicator-label'>Submit</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    <Link to='/auth/login'>
                                        <button
                                            type='button'
                                            id='kt_login_password_reset_form_cancel_button'
                                            className='btn btn-light'
                                            disabled={formik.isSubmitting || !formik.isValid}
                                        >
                                            Cancel
                                        </button>
                                    </Link>{' '}
                                </div>
                                {/* end::Form group */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OtpVerification