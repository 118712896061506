import React, { useState, useEffect } from 'react';
import { ChartsWidget3 } from '../../../_metronic/partials/widgets' 
import { KTIcon } from '../../../_metronic/helpers' 
import { Customer_GET_data, Dashboard_stats } from '../../modules/auth/core/_requests'
import { Link } from 'react-router-dom'  

interface OrderItem {
  order_item_name: string;
  total_qty: number;
  total_sale: number;
}

interface DailySales {
  order_date: string;
  total_sales: number;
}

interface Data {
  total_orders: string;
  pending_orders: string;
  completed_orders: string;
  total_customers: string;
  top_order_items: OrderItem[];
  daily_sales: DailySales[];
}

interface CustomersData {
  contact_address: string;
  contact_created_on: string;
  contact_id: string;
  contact_mobile: string;
  contact_name: string;
  contact_tags: string[] | string | null | undefined; // Define the type of contact_tags
}

const Dashboard = () => {
  const [stats, setStats] = useState<Data>();
  const [customers, setCustomers] = useState<CustomersData[]>([]);

  const UserDetails: any = localStorage.getItem('user-details')
  const userDetailsObject = JSON.parse(UserDetails);
  const customerId = userDetailsObject?.cust_id

  const fetchStatsData = async (dashboard_stats: any) => {
    try {
      const response = await Dashboard_stats(dashboard_stats);
      setStats(response.data);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchStatsData("0");
  }, []);

  const fetchContactsList = async (get_all_contacts: any, cust_id: any) => {
    try {
      const response = await Customer_GET_data(get_all_contacts, cust_id);
      setCustomers(response.data.contact_details);

    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  useEffect(() => {
    fetchContactsList("1", customerId);
  }, []);


  return (
    <>
      <div className='card bg-gray mb-5'>
        <div className='card-header border-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Dashboard</span>
          </h3>
          <div className='card-toolbar'>
            <div className="d-flex align-items-center position-relative">
              <input type="date"
                data-kt-ecommerce-product-filter="search"
                className="form-control form-control-solid w-250px"
                placeholder="Search Order Id"
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row g-5 g-xl-8 mt-2'> 
        <div className='col-xl-3'>
          <Link to={`/total-orders`}>
            <span className={`card bg-success hoverable card-xl-stretch mb-5 mb-xl-8`}>
              <div className='card-body'>
                <KTIcon iconName='basket' className={`text-white fs-3x ms-n1`} />
                <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>Total Orders</div>
                <div className={`fw-semibold text-white fs-5`}>{stats?.total_orders}</div>
              </div>
            </span>
          </Link>
        </div> 

        <div className='col-xl-3'>
          <Link to={`/order-status/3/`}>
            <span className={`card bg-primary hoverable card-xl-stretch mb-5 mb-xl-8`}>
              <div className='card-body'>
                <KTIcon iconName='cheque' className={`text-white fs-3x ms-n1`} />
                <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>Orders Completed</div>
                <div className={`fw-semibold text-white fs-5`}>{stats?.completed_orders}</div>
              </div>
            </span>
          </Link>
        </div>

        <div className='col-xl-3'>
          <Link to={`/order-status/0/`}>
            <span className={`card bg-danger hoverable card-xl-stretch mb-5 mb-xl-8`}>
              <div className='card-body'>
                <KTIcon iconName='chart-simple-3' className={`text-white fs-3x ms-n1`} />
                <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>Pending Orders</div>
                <div className={`fw-semibold text-white fs-5`}>{stats?.pending_orders}</div>
              </div>
            </span>
          </Link>
        </div>

        <div className='col-xl-3'>
          <Link to={`/customers`}>
            <a className={`card bg-info hoverable card-xl-stretch mb-5 mb-xl-8`}>
              <div className='card-body'>
                <KTIcon iconName='user' className={`text-white fs-3x ms-n1`} />
                <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>Customers</div>
                <div className={`fw-semibold text-white fs-5`}>{customers.length}</div>
              </div>
            </a>
          </Link>
        </div> 
      </div>

      <div className='row g-5 g-xl-8 '> 
        <div className='col-xl-6'>
          <ChartsWidget3 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        <div className='col-xl-6'>
          <div className='card card-xl-stretch mb-5 mb-xl-8'>
            <div className='card-header align-items-center border-0'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bold text-dark'>Most Orders Items</span>
                <span className="text-muted fw-semibold fs-7">for this Month</span> 
              </h3>
            </div>
            <div className='card-body py-3' style={{ overflowY: 'auto', maxHeight: '350px' }}>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-3'>
                  <thead>
                    <tr>
                      <th className='p-0 min-w-120px'></th>
                      <th className='p-0 min-w-150px'></th>
                      <th className='p-0 min-w-120px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats?.top_order_items?.length ? (
                      stats.top_order_items.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <a className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {item.order_item_name.length > 25 ? `${item.order_item_name.substring(0, 25)}...` : item.order_item_name}
                            </a>
                          </td>
                          <td className='text-center'>
                            <span className='text-muted fw-semibold d-block fs-7'>Qty</span>
                            <span className='text-dark fw-bold d-block fs-5'>{item.total_qty}</span>
                          </td>
                          <td>
                            <span className='text-muted fw-semibold d-block fs-7'>Earnings</span>
                            <span className='text-dark fw-bold d-block fs-5'>{item.total_sale}</span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center text-muted fw-semibold fs-7 pt-20">
                          No data available
                        </td>
                      </tr>
                    )} 
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default Dashboard