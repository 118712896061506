import axios from 'axios'
import { AuthModel, UserModel } from './_models'
const API_URL = process.env.REACT_APP_API_URL

const API_URL_NEW = 'https://digitalfactory.co.in/df_whatsapp/api' /// TESTING API
const GRAPH_API = 'https://graph.facebook.com/v18.0/162896006917111'
const GRAPH_CATALOGUE_API = 'https://graph.facebook.com/v18.0/7255752724481328'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL_NEW}/forgot_password`
export const LOGIN_URL_NEW = `${API_URL_NEW}/login`
export const OTP_VALIDATE_URL = `${API_URL_NEW}/validate`
export const GET_USER_BY_ACCESSTOKEN_URL_NEW = `${API_URL_NEW}/verify_token`
export const PROFILE_URL = `${API_URL_NEW}/profile`
export const WHATSAPP_CHAT_URL = `${API_URL_NEW}/whatsapp_chat`
export const CUSTOMERS = `${API_URL_NEW}/customers`
export const CONTACTS = `${API_URL_NEW}/contacts`
export const TAGS = `${API_URL_NEW}/contact_tags`

//GRAPH API//
export const WABA_TEMPLATE = `${GRAPH_API}/message_templates`
export const WABA_PRODUCT_CATALOGUES = `${GRAPH_CATALOGUE_API}/products`

//Team// 
export const NEW_ORDER_URL = `${API_URL_NEW}/otp`
export const ORDERS = `${API_URL_NEW}/orders`
export const ORDERS_REPORT = `${API_URL_NEW}/reports`
export const PRODUCTS = `${API_URL_NEW}/products_v1`
export const SMS = `${API_URL_NEW}/addons`
export const DASHBOARD = `${API_URL_NEW}/functions`
export const BROADCAST = `${API_URL_NEW}/broadcast`


// Server should return AuthModel
export function login(user_email: string, user_password: string) {
  return axios.post<AuthModel>(LOGIN_URL_NEW, {
    user_email,
    user_password,
  })
}

export function ProfileUpdates(
  formData: FormData,
  company_name: string,
  company_phone: string,
  company_email: string,
  company_address: string,
  company_additional_add: string,
  company_zipcode: string,
  company_city: string,
  company_state: string,
  company_country: string,
) {
  formData.append('company_name', company_name);
  formData.append('company_phone', company_phone);
  formData.append('company_email', company_email);
  formData.append('company_address', company_address);
  formData.append('company_additional_add', company_additional_add);
  formData.append('company_zipcode', company_zipcode);
  formData.append('company_city', company_city);
  formData.append('company_state', company_state);
  formData.append('company_country', company_country);
  return axios.post<AuthModel>(PROFILE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set the correct content type for file uploads
    },
  });
}


// Server should return AuthModel
export function register(
  mobile: string,
  //password: string,
  //password_confirmation: string
) {
  return axios.post(OTP_VALIDATE_URL, {
    mobile,
    // password,
    // password_confirmation,
  })
}

// Server should return AuthModel
export function registerPassword(
  verify_phone: string,
  verify_password: string,
) {
  return axios.post(OTP_VALIDATE_URL, {
    verify_phone,
    verify_password,
  })
}

export function resetPassword(
  password: string,
  password_confirmation: string,
  user_email: string
) {
  return axios.post(REQUEST_PASSWORD_URL, {
    password,
    password_confirmation,
    user_email
  })
}


export function updateNewPassword(
  current_password: string,
  new_password: string,
  user_email: string
) {
  return axios.post(PROFILE_URL, {
    current_password,
    new_password,
    user_email
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function requestOTP(
  user_otp: string,
  user_mobile: string,
) {
  return axios.post(OTP_VALIDATE_URL, {
    user_otp,
    user_mobile
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL_NEW, {
    api_token: token,
  })
}

//Products & Category

export function PickupOrder(order_delivery_type: string, order_name: string, order_mobile_number: string, order_delivery_address1: string, order_delivery_address2: string,
  order_delivery_date: string, order_delivery_time: string, order_notes: string, order_details: any, order_total: number, device_token: any) {
  return axios.post(NEW_ORDER_URL, {
    order_delivery_type,
    order_name,
    order_mobile_number,
    order_delivery_address1,
    order_delivery_address2,
    order_delivery_date,
    order_delivery_time,
    order_notes,
    order_details,
    order_total,
    device_token
  })
}


export function validateOTP(verify_device_token: string, verify_order_id: string,
  verify_otp: string) {
  return axios.post(NEW_ORDER_URL, {
    verify_device_token,
    verify_order_id,
    verify_otp
  })
}

export function SuccessOrder_post(order_id: string) {
  return axios.post(ORDERS, {
    order_id,
  })
}

export function Dashboard_stats(dashboard_stats: string
) {
  return axios.get(DASHBOARD, {
    params: {
      dashboard_stats: dashboard_stats
    },
  });
}

export function Product_cate_post(product_cat_title: string) {
  return axios.post(PRODUCTS, {
    product_cat_title
  })
}

export function UserDeatils_Post(verify_api_token: string, verify_cust_id: string) {
  return axios.post(PROFILE_URL, {
    verify_api_token,
    verify_cust_id
  })
}


export function Category_post_data(
  formData: FormData,
  prod_cat_title: string,
) {
  formData.append('prod_cat_title', prod_cat_title);
  return axios.post(PRODUCTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function GET_Product_Cat(get_product_cat: string) {
  return axios.get(PRODUCTS, {
    params: {
      get_product_cat: get_product_cat,
    },
  });
}

export function Edit_Product_Cat(get_product_cat: string) {
  return axios.get(PRODUCTS, {
    params: {
      get_product_cat: get_product_cat,
    },
  });
}

// export function Update_Product_Cat(product_cat_upd_id: string, product_cat_title_upd: string) {
//   return axios.post(PRODUCTS, {
//     product_cat_upd_id: product_cat_upd_id, product_cat_title_upd: product_cat_title_upd
//   })
// }

export function Update_Product_Cat(
  formData: FormData,
  product_cat_upd_id: string,
  product_cat_title_upd: string,
) {
  formData.append('product_cat_upd_id', product_cat_upd_id);
  formData.append('product_cat_title_upd', product_cat_title_upd);
  return axios.post(PRODUCTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function DELETE_Category(product_cat_del_id: string) {
  return axios.post(PRODUCTS, {
    product_cat_del_id
  })
}

export function product_post_data(
  formData: FormData,
  product_name: string,
  product_description: string,
  product_regular_price: string,
  product_sale_price: string,
  product_stock: string,
  product_created_by: number,
) {
  formData.append('product_name', product_name);
  formData.append('product_description', product_description);
  formData.append('product_regular_price', product_regular_price);
  formData.append('product_sale_price', product_sale_price);
  formData.append('product_stock', product_stock);
  formData.append('product_created_by', product_created_by.toString());
  return axios.post(PRODUCTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}


export function all_products(get_products: string
) {
  return axios.get(PRODUCTS, {
    params: {
      get_products: get_products
    },
  });
}

export function products_delete(product_del_id: string) {
  return axios.post(PRODUCTS, {
    product_del_id: product_del_id
  })
}

export function Edit_product_data(get_products: string
) {
  return axios.get(PRODUCTS, {
    params: {
      get_products: get_products
    },
  });
}

export function Products_Update(
  formData: FormData,
  product_id_upd: string,
  product_name_upd: string,
  product_description_upd: string,
  product_regular_price_upd: string,
  product_sale_price_upd: string,
  product_stock_upd: string,
) {
  formData.append('product_id_upd', product_id_upd);
  formData.append('product_name_upd', product_name_upd);
  formData.append('product_description_upd', product_description_upd);
  formData.append('product_regular_price_upd', product_regular_price_upd);
  formData.append('product_sale_price_upd', product_sale_price_upd);
  formData.append('product_stock_upd', product_stock_upd);
  return axios.post(PRODUCTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Important: Set the correct content type for file uploads
    },
  });
}

export function orders_get_all(orders: number) {
  return axios.get(ORDERS, {
    params: {
      orders: orders
    },
  });
}


export function orders_get_reports(get_reports: number) {
  return axios.get(ORDERS_REPORT, {
    params: {
      get_reports: get_reports
    },
  });
}

export function orders_view_details(order_id: string, order_mode: any) {
  return axios.get(ORDERS, {
    params: {
      order_id: order_id,
      order_mode: order_mode,
    },
  });
}

export function SuccessOrders(order_id: string, order_mode: any, payment_link_id: any, payment_status: any) {
  return axios.get(ORDERS, {
    params: {
      order_id: order_id,
      order_mode: order_mode,
      payment_link_id: payment_link_id,
      payment_status: payment_status

    },
  });
}



export function orders_alert(order_ws_id: string) {
  return axios.get(ORDERS, {
    params: {
      order_ws_id: order_ws_id
    },
  });
}

export function orders_update(
  formData: FormData,
  order_upd_id: string,
  order_upd_status: string,
  order_upd_notes: string,
  order_upd_by: number
) {
  formData.append('order_upd_id', order_upd_id);
  formData.append('order_upd_status', order_upd_status);
  formData.append('order_upd_notes', order_upd_notes);
  formData.append('order_upd_by', order_upd_by.toString());
  return axios.post(ORDERS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
export function SMS_config_post_data(
  sms_user_name: string,
  sms_password: string,
  sms_sender_id: string,
  sms_website_url: string,
  addon_type: string,
) {
  return axios.post(SMS, {
    sms_user_name,
    sms_password,
    sms_sender_id,
    sms_website_url,
    addon_type,
  })
}

export function sms_addons_update(
  addon_id: string,
  sms_user_name_upd: string,
  sms_password_upd: string,
  sms_website_url_upd: string,
  sms_sender_id_upd: string,
  addon_type_upd: string,
) {
  return axios.post(SMS, {
    addon_id,
    sms_user_name_upd,
    sms_password_upd,
    sms_website_url_upd,
    sms_sender_id_upd,
    addon_type_upd,
  })
}

export function Addons(addon: number) {
  return axios.get(SMS, {
    params: {
      get_addon_details: addon
    },
  });
}

export function SMS_setting_post_data(
  formData: FormData,
  sms_admin_mobile_number: string,
) {
  formData.append('sms_admin_mobile_number', sms_admin_mobile_number);
  return axios.post(PRODUCTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Wp_config_post_data(
  wp_phone_number_id: string,
  wp_business_account_id: string,
  access_token: string,
  wp_number: string,
  addon_type: string,
) {
  return axios.post(SMS, {
    wp_phone_number_id,
    wp_business_account_id,
    access_token,
    wp_number,
    addon_type,
  })
}

export function Wp_config_upd_data(
  addon_id: string,
  wp_phone_number_id_upd: string,
  wp_business_account_id_upd: string,
  access_token_upd: string,
  wp_number_upd: string,
  addon_type_upd: string,
) {
  return axios.post(SMS, {
    addon_id,
    wp_phone_number_id_upd,
    wp_business_account_id_upd,
    access_token_upd,
    wp_number_upd,
    addon_type_upd,
  })
}

export function Wp_setting_post_data(
  formData: FormData,
  admin_wp_number: string,
) {
  formData.append('admin_wp_number', admin_wp_number);
  return axios.post(PRODUCTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Email__config_post_data(
  email_user_name: string,
  email_user_password: string,
  email_smtp_address: string,
  addon_type: string,
) {
  return axios.post(SMS, {
    email_user_name,
    email_user_password,
    email_smtp_address,
    addon_type,
  })
}

export function Email_config_upd_data(
  addon_id: string,
  email_user_name_upd: string,
  email_user_password_upd: string,
  email_smtp_address_upd: string,
  addon_type_upd: string,
) {
  return axios.post(SMS, {
    addon_id,
    email_user_name_upd,
    email_user_password_upd,
    email_smtp_address_upd,
    addon_type_upd,
  })
}

export function WABUDATA(wacustData: string) {
  return axios.get(WHATSAPP_CHAT_URL, {
    params: {
      wacustData: wacustData,
    },
  });
}

export function WACHAT_MESSAGES(wa_message_id: string) {
  return axios.post(WHATSAPP_CHAT_URL, {
    wa_message_id
  })
}

export function WACHAT_MESSAGE_SEND(wa_message_sender_id: string, wa_message_receiver_id: string, wa_individual_message: string) {
  return axios.post(WHATSAPP_CHAT_URL, {
    wa_message_sender_id,
    wa_message_receiver_id,
    wa_individual_message
  })
}


export function Search_product(search_term: string) {
  return axios.get(PRODUCTS, {
    params: {
      search_term: search_term,
    },
  });
}

export function GET_WA_MESSAGE_TEMPLATE(access_token: string) {
  return axios.get(WABA_TEMPLATE, {
    params: {
      access_token: access_token
    },
  });
}

export function GET_WA_PRODUCT_CATALOGUES(access_token: string, fields: any, limit: number, before: string, after: any) {
  return axios.get(WABA_PRODUCT_CATALOGUES, {
    params: {
      access_token: access_token,
      fields: fields,
      limit: limit,
      before: before,
      after: after,
    },
  });
}

interface CustomersData {
  order_person_name: string;
  order_contact_number: string;
  delivery_address_1: string;
  delivery_address_2: string;
}

export function whatsapp_broadcast_segment_POST(
  segment_name: string,
  segment_customers: CustomersData[],
  segment_created_by: string,
) {
  return axios.post(BROADCAST, {
    segment_name,
    segment_customers,
    segment_created_by,
  })
}

export function whatsapp_broadcast_segment_GET(all_segments: string) {
  return axios.get(BROADCAST, {
    params: {
      all_segments: all_segments
    },
  });
}

export function whatsapp_broadcast_GET(all_broadcast: string) {
  return axios.get(BROADCAST, {
    params: {
      all_broadcast: all_broadcast
    },
  });
}

export function whatsapp_broadcast_filters(
  broadcast_select_type: string,
  broadcast_select_format: string,
  broadcast_search_type: string,
  broadcastmode: number,

) {
  return axios.post(CUSTOMERS, {
    broadcast_select_type,
    broadcast_select_format,
    broadcast_search_type,
    broadcastmode,
  })
}

export function whatsapp_broadcast_template(
  // templateId: string,
  variable: string[],
  template_segment_id: string[],
) {
  return axios.post(BROADCAST, {
    // templateId,
    variable,
    template_segment_id,
  })
}

export function whatspp_broadcast_post(
  formData: FormData,
  variable: string[],
  template_segment_id: string[],
) {
  variable.forEach((value) => {
    formData.append(`variable`, value);
  });
  template_segment_id.forEach(id => {
    formData.append('template_segment_id[]', id);
  });
  return axios.post(BROADCAST, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function whatsapp_broadcast_create(
  broadcast_name: string,
  broadcast_type: string,
  schedule_date: string,
  schedule_time: string,
  broadcast_variables: any,
  broadcast_recipients: number,
  broadcast_template: any
) {
  return axios.post(BROADCAST, {
    broadcast_name,
    broadcast_type,
    schedule_date,
    schedule_time,
    broadcast_variables,
    broadcast_recipients,
    broadcast_template
  })
}

export function Customer_POST_data(
  formData: FormData,
  contact_name: string,
  contact_email: string,
  contact_mobile: string,
  contact_address: string,
  contact_user_id: number,
  user_token: string,
  contact_cust_id: string,
) {
  formData.append('contact_name', contact_name);
  formData.append('contact_email', contact_email);
  formData.append('contact_mobile', contact_mobile);
  formData.append('contact_address', contact_address);
  formData.append('contact_user_id', contact_user_id.toString());
  formData.append('user_token', user_token);
  formData.append('contact_cust_id', contact_cust_id);
  return axios.post(CONTACTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Customer_GET_data(get_all_contacts: string, contact_cust_id: string) {
  return axios.get(CONTACTS, {
    params: {
      get_all_contacts: get_all_contacts,
      contact_cust_id: contact_cust_id
    },
  });
}

export function Customer_EDIT_Details(contact_id: string, contact_cust_id: string) {
  return axios.get(CONTACTS, {
    params: {
      contact_id: contact_id,
      contact_cust_id: contact_cust_id
    },
  });
}

export function Customer_UPDATE_data(
  formData: FormData,
  contact_id_upd: string,
  contact_name_upd: string,
  contact_email_upd: string,
  contact_mobile_upd: string,
  contact_address_upd: string,
  contact_user_id_upd: number,
  user_token_upd: string,
  contact_cust_id_upd: string,

) {
  formData.append('contact_id_upd', contact_id_upd);
  formData.append('contact_name_upd', contact_name_upd);
  formData.append('contact_email_upd', contact_email_upd);
  formData.append('contact_mobile_upd', contact_mobile_upd);
  formData.append('contact_address_upd', contact_address_upd);
  formData.append('contact_user_id_upd', contact_user_id_upd.toString());
  formData.append('user_token_upd', user_token_upd);
  formData.append('contact_cust_id_upd', contact_cust_id_upd);
  return axios.post(CONTACTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function wp_Customer_UPDATE_data(
  formData: FormData,
  contact_id_upd: string,
  contact_name_upd: string,
  contact_email_upd: string,
  contact_mobile_upd: string,
  contact_address_upd: string,
  contact_user_id_upd: string,
  user_token_upd: string,
  contact_cust_id_upd: string,

) {
  formData.append('contact_id_upd', contact_id_upd);
  formData.append('contact_name_upd', contact_name_upd);
  formData.append('contact_email_upd', contact_email_upd);
  formData.append('contact_mobile_upd', contact_mobile_upd);
  formData.append('contact_address_upd', contact_address_upd);
  formData.append('contact_user_id_upd', contact_user_id_upd);
  formData.append('user_token_upd', user_token_upd);
  formData.append('contact_cust_id_upd', contact_cust_id_upd);
  return axios.post(CONTACTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function Customer_DELETE_data(contact_del_id: string, contact_cust_id: string) {
  return axios.post(CONTACTS, {
    contact_del_id: contact_del_id,
    contact_cust_id: contact_cust_id
  })
}

export function Tags_POST_data(cust_id: string, tag_name: string, tag_created_by: string, user_token: string) {
  return axios.post(TAGS, {
    cust_id,
    tag_name,
    tag_created_by,
    user_token
  })
}

export function Tags_GET_data(get_all_tags: string, tag_cust_id: string) {
  return axios.get(TAGS, {
    params: {
      get_all_tags: get_all_tags,
      tag_cust_id: tag_cust_id,
    },
  });
}

export function customer_wp_update_data(
  formData: FormData,
  contact_name_upd: string,
  // contact_phone_upd: string,
  contact_email_upd: string,
  contact_address_upd: string,
  user_id: number,
) {
  formData.append('contact_name_upd', contact_name_upd);
  // formData.append('contact_phone_upd', contact_phone_upd);
  formData.append('contact_email_upd', contact_email_upd);
  formData.append('contact_address_upd', contact_address_upd);
  formData.append('user_id', user_id.toString());
  return axios.post(CONTACTS, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function whatsapp_broadcast_download(broadcast_log_data: string, broadcast_log_cid: string) {
  return axios.get(BROADCAST, {
    params: {
      broadcast_log_data: broadcast_log_data,
      broadcast_log_cid: broadcast_log_cid,
    },
  });
}

export function Segment_DELETE_data(segment_del_id: string, segment_cust_id: string) {
  return axios.post(BROADCAST, {
    segment_del_id: segment_del_id,
    segment_cust_id: segment_cust_id
  })
}

export function CustomerName_UPDATE(quick_customer_name_upd: string, quick_order_cust_id: number) {
  return axios.post(BROADCAST, {
    quick_customer_name_upd: quick_customer_name_upd,
    quick_order_cust_id: quick_order_cust_id
  })
}

export function DeliveryDate_UPDATE(quick_delivery_date_upd: string, quick_order_cust_id: number) {
  return axios.post(BROADCAST, {
    quick_delivery_date_upd: quick_delivery_date_upd,
    quick_order_cust_id: quick_order_cust_id
  })
}

export function Notes_UPDATE(quick_notes_upd: string, quick_order_cust_id: number) {
  return axios.post(BROADCAST, {
    quick_notes_upd: quick_notes_upd,
    quick_order_cust_id: quick_order_cust_id
  })
}

export function customerXLData(contact_xl_data: string, contact_cust_id: number, contact_user_id: string) {
  return axios.post(CONTACTS, {
    contact_xl_data: contact_xl_data,
    contact_cust_id: contact_cust_id,
    contact_user_id: contact_user_id
  })
}

export function whatspp_chat_template_post(
  message_chat_media: FormData,
) {
  return axios.post(WHATSAPP_CHAT_URL, message_chat_media, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function whatspp_chat_message_post(message_chat_user_id: string, message_chat_template: string, message_chat_details: string) {
  return axios.post(WHATSAPP_CHAT_URL, {
    message_chat_user_id: message_chat_user_id,
    message_chat_template: message_chat_template,
    message_chat_details: message_chat_details,
  })
}

export function whatspp_chat_image_post(
  chat_media: FormData,
  chat_media_type: string,
  chat_media_caption:string,
  chat_receiver_id:string,
  chat_sender_id:string,
) {
  chat_media.append('chat_media_type', chat_media_type);
  chat_media.append('chat_media_caption', chat_media_caption);
  chat_media.append('chat_receiver_id', chat_receiver_id);
  chat_media.append('chat_sender_id', chat_sender_id);
  return axios.post(WHATSAPP_CHAT_URL, chat_media, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function whatspp_chat_image(message_chat_template: string) {
  return axios.post(WHATSAPP_CHAT_URL, {
    message_chat_template: message_chat_template,
  })
}

export function PAYMENT_REQUEST_SEND(payment: string, notes: string, chat_number: string, chat_sender_id: string) {
  return axios.post(WHATSAPP_CHAT_URL, {
    payment: payment,
    notes: notes,
    chat_number: chat_number,
    chat_sender_id: chat_sender_id,
  })
}

export function CONTACTS_ADD_CHAT(contact_name: string, contact_mobile: string, contact_cust_id: string) {
  return axios.post(WHATSAPP_CHAT_URL, {
    contact_name: contact_name,
    contact_mobile: contact_mobile,
    contact_cust_id: contact_cust_id,
  })
} 

export function Razorpay_config_post_data(
  razorpay_key_id: string,
  razorpay_key_secret: string, 
  addon_type: string,
) {
  return axios.post(SMS, {
    razorpay_key_id,
    razorpay_key_secret, 
    addon_type,
  })
}

export function Razorpay_config_upd_data(
  addon_id: string,
  razorpay_key_id_upd: string,
  razorpay_key_secret_upd: string, 
  addon_type_upd: string,
) {
  return axios.post(SMS, {
    addon_id,
    razorpay_key_id_upd,
    razorpay_key_secret_upd, 
    addon_type_upd,
  })
}