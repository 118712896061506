/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react' 
import { Form, Formik, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup' 

const Step1 = () => {
  const [loading, setLoading] = useState(false)

  const initialSettingsValues = {
    catalogue_id: '', 
}

const validationSettings = Yup.object().shape({
    catalogue_id: Yup.string().required('Catalogue ID is required'), 
});

const handleSubmitSettings = (values: any) => {
    console.log(values, 'values')
}

  return (
    <div className='col-md-8 offset-md-2 card p-8'>
      <h2 className='fw-bolder d-flex align-items-center text-dark'>
        Enter Facebook Catalogue ID
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='Billing is issued based on your selected account type'
        ></i>
      </h2>

      <div className='text-gray-400 fw-bold fs-6'>
        Copy Catalog ID from Business Settings page and paste in field below and Hit the
        <span className='link-primary fw-bolder'>
          {' '}
          Connect button
        </span>
        .
      </div>

      <div className='mt-10'>
        <Formik validationSchema={validationSettings} initialValues={initialSettingsValues} onSubmit={handleSubmitSettings}>
          {() => (
            <Form>

              <div className="form-group"> 
                <Field type="text" name="catalogue_id" className="form-control" placeholder="Enter Catalogue ID"/>
                <ErrorMessage name="catalogue_id" component="div" className="text-danger" />
              </div>
 
              <div className='d-flex justify-content-end mt-5'>
                <button type='submit' className='btn btn-primary' disabled={loading}>
                  {!loading && 'Connect Now'}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export { Step1 }
