import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { register } from '../core/_requests'
import { Link } from 'react-router-dom'
import { useAuth } from '../core/Auth'
import { useNavigate } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import LeftSide from './LeftSide'



const initialValues = {
  mobile: '',
  acceptTerms: false,
}

const validationSchema = Yup.object().shape({
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
    .required('Mobile number is required'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
});


export function Registration() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()


  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data } = await register(
          values.mobile
        )
        if (data == 'Found') {
          navigate("/auth/otp-verification")
          localStorage.setItem("user_mobile", values.mobile);
        }
        else if (data == 'Already Registered') {
          formik.setErrors({ mobile: "Already Registered! Kindly Login to Access Dashboard" })
        }
        else if (data == 'Not Found') {
          formik.setErrors({ mobile: "User Not Found" });
        }
        setLoading(false)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (

    <>

      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Privacy Policy</h3>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <div className='row'>
                <p className="fs-4">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <p className="fs-4">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <p className="fs-4">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <p className="fs-4">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <p className="fs-4">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                {/* <p className="fs-4">
                  Please note that this response is provided for informational purposes only and should not be considered as legal advice. For specific legal matters related to your situation, it is recommended to consult with a qualified attorney.
                </p>
                <p className="fs-4">
                  Participants are free to use the information they receive, but they are prohibited from revealing the identity or affiliation of any speaker or participant, nor can they attribute any statements to a particular individual. This allows participants to speak more freely and openly without fear of reprisal or retaliation.The rule helps to ensure that sensitive or confidential information is not leaked to the public, while also allowing participants to benefit from the insights and expertise of others in the group.

                </p>

                <div className="mt-5 mb-20">
                  <h1 className='mb-5'>Leaders Club Confidential Agreement</h1>
                  <ul className="fs-4">
                    <li>You will not share your company's confidential information.</li>
                    <li>You will not discuss the exact context of our meetups outside the Leaders Club audience.</li>
                    <li>You can refer to any company's CXOs name with their approvals.</li>
                    <li>You will not take any pictures or screenshots of virtual meetups.</li>
                    <li>You will not share the context of any meeting on any social media.</li>
                    <li>You will not invite people without prior approval.</li>
                    <li>Any promotional material content is prohibited</li>
                  </ul>
                </div> */}
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className='row me-0'>
        <div className='col-xl-6 login_left_image d-none d-lg-block bg-primary vh-100'>
          <LeftSide />
        </div>
        <div className='col-xl-6 d-none d-lg-block login_bg'>
          <div className="d-flex flex-column p-10">
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <div className='text-center mb-11'>
                <img src={toAbsoluteUrl('/media/auth/add-friend.png')} className='registration_signup_img' alt='Metronic' />
                <h1 className='text-dark fw-bolder'>Sign Up</h1>
              </div>

              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Enter Mobile Number</label>
                <input
                  placeholder='Enter your mobile number'
                  type='text' // Change 'email' to 'number' here 
                  id="user_mobile" // You can change the 'id' to 'user_mobile' or any other suitable identifier
                  autoComplete='off'
                  {...formik.getFieldProps('mobile')} // Update the formik field props accordingly
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.mobile && formik.errors.mobile },
                    {
                      'is-valid': formik.touched.mobile && !formik.errors.mobile,
                    }
                  )}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.mobile}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-8'>
                <label className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='kt_login_toc_agree'
                    {...formik.getFieldProps('acceptTerms')}
                  />
                  <span>
                    I accept the{' '}
                    <span data-bs-toggle="modal" style={{ cursor: 'pointer' }} data-bs-target="#staticBackdrop" className='link-primary'>
                      Privacy Policy
                    </span>
                    .
                  </span>
                </label>
                {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.acceptTerms}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>

                <div className='text-gray-500 text-center fw-semibold fs-6'>
                  Already Registered?{' '}
                  <Link to='/auth/login'>
                    Login
                  </Link>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>

      <div className='row me-0 d-sm-none d-block'>
        <div className='col-xl-6'>
          <div className="d-flex flex-column w-md-600px p-10 login_mob">
            <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px mt-7 mb-7">
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form_mob'
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <div className='text-center mb-11'>
                  <img src={toAbsoluteUrl('/media/auth/add-friend.png')} className='registration_signup_img' alt='Metronic' />
                  <h1 className='text-dark fw-bolder'>Sign Up</h1>
                </div>

                {formik.status && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                )}

                <div className='fv-row mb-8'>
                  <label className='form-label fw-bolder text-dark fs-6'>Enter Mobile Number</label>
                  <input
                    placeholder='Enter your mobile number'
                    type='text' // Change 'email' to 'number' here 
                    id="user_mobile" // You can change the 'id' to 'user_mobile' or any other suitable identifier
                    autoComplete='off'
                    {...formik.getFieldProps('mobile')} // Update the formik field props accordingly
                    className={clsx(
                      'form-control bg-transparent',
                      { 'is-invalid': formik.touched.mobile && formik.errors.mobile },
                      {
                        'is-valid': formik.touched.mobile && !formik.errors.mobile,
                      }
                    )}
                  />
                  {formik.touched.mobile && formik.errors.mobile && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.mobile}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-8'>
                  <label className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='kt_login_toc_agree'
                      {...formik.getFieldProps('acceptTerms')}
                    />
                    <span>
                      I accept the{' '}
                      <span data-bs-toggle="modal" style={{ cursor: 'pointer' }} data-bs-target="#staticBackdrop" className='link-primary'>
                        Privacy Policy
                      </span>
                      .
                    </span>
                  </label>
                  {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.acceptTerms}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='text-center'>
                  <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>

                  <div className='text-gray-500 text-center fw-semibold fs-6'>
                    Already Registered?{' '}
                    <Link to='/auth/login'>
                      Login
                    </Link>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
        <div className='col-xl-6 bg-primary'>
          <LeftSide />
        </div>
      </div>
    </>
  )
}
