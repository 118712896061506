import { useState, useEffect } from 'react'
import clsx from 'clsx'
import { HeaderNotificationsMenu, HeaderUserMenu, Search } from '../../../partials'

import { UserDeatils_Post } from '../../../../app/modules/auth/core/_requests'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'

interface UserDetails {
  company_name: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  pic: string;
  zipcode: string;
  email: string;
  address_line1: string;
  address_line2: string;
}

const Navbar = () => {
  const UserDetails: any = localStorage.getItem('user-details')
  const [details, setDetails] = useState<UserDetails>()


  const userDetailsObject = JSON.parse(UserDetails);
  const user_token = userDetailsObject?.api_token
  const customerId = userDetailsObject?.cust_id

  useEffect(() => {
    UserDeatils_Post(user_token, customerId)
      .then(response => {
        setDetails(response.data);
      })
      .catch(error => {
        console.error('API Error:', error);
      });
  }, [user_token])

  return (
    <>
      <div className="align-self-center fs-5 fw-bold text-success">Welcome Back <br></br><span className='fs-6 text-dark'>{details?.company_name}</span></div>
      <div className='app-navbar flex-shrink-0'>
       

        <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
          <Search />
        </div>

        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            className={btnClass}
          >
            <span className="svg-icon svg-icon-muted svg-icon-2hx"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="currentColor" />
              <path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="currentColor" />
            </svg>
            </span>
          </div>
          <HeaderNotificationsMenu />
        </div>
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <div className="symbol symbol-circle symbol-25px">
              <div className="symbol-label bg-primary">
                <span className="fs-7 text-inverse-primary">SE</span>
              </div>
            </div>
          </div>
          {<HeaderUserMenu />}
        </div>
      </div>
    </>

  )
}

export { Navbar }
