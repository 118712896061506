/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import {KTIcon} from '../../../helpers'
import { orders_get_all} from '../../../../app/modules/auth/core/_requests'


interface ordersData {
  order_id: string;
  contact_number: string;
  contact_person: string;
  order_total: string;
  order_status: string;
  order_on: string;
}

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
}) => 

 
{
  const [orders, setOrders] = useState<ordersData[]>([]);


  const fetchProductsData = async (orders: any) => {
    try {
        const response = await orders_get_all(orders);
        setOrders(response.data.order_details);
        // setIsLoading(false);
    } catch (error) {
        console.error('Error fetching members:', error);
    }
};

useEffect(() => {
    fetchProductsData("0");
}, []);


  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body'>
        <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} />

        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>{title}</div>

        <div className={`fw-semibold text-${descriptionColor}`}>{orders.length}</div>
      </div>
    </a>
  )
}

export {StatisticsWidget5}
