import React, { useState } from 'react'
import { Form, Formik, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import { KTIcon } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'

const WhatsappStore = () => {
    const [loading, setLoading] = useState(false)

    const initialSettingsValues = {
        business_id: '',
    }

    const validationSettings = Yup.object().shape({
        business_id: Yup.string().required('Business ID is required'),
    });

    const handleSubmitSettings = (values: any) => {
        console.log(values, 'values')
    }

    return (
        <div>
            <Link to='/settings/api'>
                <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                </button>
            </Link>

            <div className='col-md-8 offset-md-2 card p-8'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    Enter Whatsapp Business ID
                    <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Billing is issued based on your selected account type'
                    ></i>
                </h2>

                <div className='text-gray-400 fw-bold fs-6'>
                    Copy Business ID from Settings page and paste in field below and Hit the
                    <span className='link-primary fw-bolder'>
                        {' '}
                        Connect button
                    </span>
                    .
                </div>

                <div className='mt-10'>
                    <Formik validationSchema={validationSettings} initialValues={initialSettingsValues} onSubmit={handleSubmitSettings}>
                        {() => (
                            <Form>

                                <div className="form-group">
                                    <Field type="text" name="business_id" className="form-control" placeholder="Enter Whatsapp Business ID" />
                                    <ErrorMessage name="business_id" component="div" className="text-danger" />
                                </div>

                                <div className='d-flex justify-content-end mt-5'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Connect Now'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default WhatsappStore  
