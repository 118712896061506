import React from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';

const SubscriptionAlertContent = ({details}:any) => {
    return (
        <div className='row w-100'>
            <div className='col-md-6 d-flex align-items-center justify-content-center'>
                <div>
                    <KTIcon iconName='information-2' className='fs-6x text-warning'/>
                    <h1>Your Subscription Expired</h1>
                    <h6 className='mt-5 lh-lg'>WhatsApp Platform Access disabled due to subscription is expired. Kindly pay to restore access.</h6>
                    <span className='text-dark fw-bold'>  Subscription Period -  </span>
                        <span className='badge bg-info text-white p-3'>{details?.subscriptions_start} To {details?.subscriptions_end}</span>
                    <button className="btn btn-danger">
                        Contact Support : +919500802940
                    </button>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='d-flex align-items-center justify-content-center'>
                    <img alt='Pic' src={toAbsoluteUrl('/media/illustrations/misc/disconnect.jpg')} className='w-100' />
                </div>
            </div>
        </div>

    //     <div className='row w-100'>
    //     <div className='col-md-6 d-flex align-items-center justify-content-center'>
    //         <div>
    //             <KTIcon iconName='information-3' className='fs-6x text-error'/>
    //             <h1>Platform Suspended</h1>
    //             <h6 className='mt-5 lh-lg'>Platform Suspended due to non-payment <br></br>of platform subscription fees.</h6><br></br>
    //             <span className='text-dark fw-bold mt-5'> Last Subscription Period -  </span>
    //                 <span className='badge bg-info text-white p-2'>{details?.subscriptions_start} To {details?.subscriptions_end}</span>
    //             <button className="btn btn-danger">
    //                 Contact Support : +919500802940
    //             </button>
    //         </div>
    //     </div>
    //     <div className='col-md-6'>
    //         <div className='d-flex align-items-center justify-content-center'>
    //             <img alt='Pic' src={toAbsoluteUrl('/media/illustrations/misc/suspended.png')} className='w-100' />
    //         </div>
    //     </div>
    // </div>
    );
};

export default SubscriptionAlertContent;
