import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { useAuth } from '../../../../app/modules/auth'
import { MutableRefObject, useState, useEffect, useRef } from 'react'
import { ToggleComponent } from '../../../assets/ts/components'
import { UserDeatils_Post } from '../../../../app/modules/auth/core/_requests'

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}

interface UserDetails {
  company_name: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  pic: string;
  zipcode: string;
  email: string;
  address_line1: string;
  address_line2: string;
}

const SidebarLogo = (props: PropsType) => {
  const UserDetails = localStorage.getItem('user-details')
  const { currentUser, logout } = useAuth()
  const [user_number, setUser_number] = useState(currentUser?.phone || '')
  const { config } = useLayout()
  const toggleRef = useRef<HTMLDivElement>(null)
  const [details, setDetails] = useState<UserDetails>()


  let apiToken: any = null;

  if (UserDetails) {
    const userDetailsObject = JSON.parse(UserDetails);
    if (userDetailsObject.api_token) {
      apiToken = userDetailsObject.api_token;
    }
  }

  useEffect(() => {
    UserDeatils_Post(apiToken, user_number)
      .then(response => {
        setDetails(response.data);
      })
      .catch(error => {
        console.error('API Error:', error);
      });
  }, [apiToken]);

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
      ? 'minimize'
      : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(toggleRef.current!) as ToggleComponent | null

      if (toggleObj === null) {
        return
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        // Set animation state
        props.sidebarRef.current!.classList.add('animating')

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [toggleRef, props.sidebarRef])

  return (
    <div className='app-sidebar-logo mt-5 pb-5 px-6 d-flex align-items-center justify-content-center' id='kt_app_sidebar_logo'>
      <Link to='/dashboard'>
        {config.layoutType === 'dark-sidebar' ? (
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/df-logo-wp.png')}
            className='h-55px rounded-2 app-sidebar-logo-default'
          />
        ) : (
          <>
            <img
              alt='Logo'
             // src={details?.pic}
             src={toAbsoluteUrl('/media/logos/df-logo-wp.png')}
              className='h-25px app-sidebar-logo-default theme-light-show'
            />
            <img
              alt='Logo'
             // src={details?.pic}
             src={toAbsoluteUrl('/media/logos/df-logo-wp.png')}
              className='h-25px app-sidebar-logo-default theme-dark-show'
            />
          </>
        )}

        <img
          alt='Logo'
        //  src={details?.pic}
        src={toAbsoluteUrl('/media/logos/df-logo-wp.png')}
          className='h-20px app-sidebar-logo-minimize'
        />
      </Link>

      {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          ref={toggleRef}
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            { active: appSidebarDefaultMinimizeDefault }
          )}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTIcon iconName='double-left' className='fs-2 rotate-180' />
        </div>
      )}
    </div>
  )
}

export { SidebarLogo }
