/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { Languages } from './Languages'
import { toAbsoluteUrl, toAbsoluteUrlImage } from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()
  const [userpic, setUserPic] = useState(currentUser?.pic || '')
  const [userrole, setUserRole] = useState(currentUser?.role || '')
 
   
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
         
      <div className='menu-item px-5'>
        <Link to={'/crafted/account/overview'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>

      <div className='menu-item px-5 my-1'>
        <Link
          to="/crafted/account/reset-password" className='menu-link px-5'>
          Reset Password
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
