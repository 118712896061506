import React, { useState, useEffect, useRef } from 'react';
import { toAbsoluteUrl, KTIcon } from '../../_metronic/helpers'
import { useAuth } from '../modules/auth'
import { useReactToPrint } from 'react-to-print';
import { Form, Formik, FormikValues, ErrorMessage, Field } from 'formik'
import { orders_get_all, orders_view_details, orders_update } from '../modules/auth/core/_requests'
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import Loader from './Loader';
const socket = io("https://app.digitalfactoryindia.com:3001");

interface ordersData {
    order_id: string;
    contact_number: string;
    contact_person: string;
    order_total: string;
    order_status: string;
    delivery_type: string;
    expected_date: string;
    expected_time: string;
    order_completed_on: string;
    order_on: string;
    order_mode:string;
    order_payment_status : any;
}

interface Orderitems {
    order_id: string;
    order_item_name: string;
    order_item_qty: string;
    order_item_price: string;
    order_item_inline_total: string;
}

interface OrderDetails {
    order_id: string;
    contact_number: string;
    contact_person: string;
    delivery_type: string;
    expected_date: string;
    expected_time: string;
    order_completed_by: string;
    order_completed_on: string;
    order_total: string;
    order_status: string;
    order_on: string;
    special_instructions: string;
    message: string;
    order_items: Orderitems[];
    order_mode:string;
    payment_status: number;

    gst:string;
    address:string;
}

const Orders = () => {
    const { order_status } = useParams<{ order_status: string }>();
    const { currentUser } = useAuth()
    const [userid, setUserid] = useState(currentUser?.user_id as number) || 0
    const [isLoading, setIsLoading] = useState(true);
    const [detailsLoading, setDetailsLoading] = useState(true);
    const [loading, setLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [orders, setOrders] = useState<ordersData[]>([]);
    const [viewPerson, setViewPerson] = useState<OrderDetails>()
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredMembers, setFilteredMembers] = useState<ordersData[]>([]);
    const [showEditModal, setShowEditModal] = useState(false)
    const [editEvent, setEditEvent] = useState<OrderDetails>()
    const [initialEditValues, setInitialEditValues] = useState(
        {
            order_upd_id: '',
            order_upd_status: '',
            order_upd_notes: ''
        }
    )
    const [filterStatus, setFilterStatus] = useState(order_status)

    const fetchProductsData = async (orders: any) => {
        try {
            const response = await orders_get_all(orders);
            setOrders(response.data.order_details);
            setIsLoading(false);

            socket.emit('ORDERS_DF_INITAL_DATA',response.data.order_details);
            socket.on("df_orders",(data:any) =>
            {
                setOrders(data);
            })
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        fetchProductsData("0");
    }, []);

    const handleViewOrders = async (order_id: any,order_mode:any) => {
        setDetailsLoading(true)
        try {
            const response = await orders_view_details(order_id,order_mode);
            setViewPerson(response.data);
            setEditEvent(response.data);
            const initialData = response.data
           // console.log(initialData.order_id, 'iddd')
           // console.log(initialData.order_status, 'stats')
            setInitialEditValues({
                order_upd_id: initialData.order_id,
                order_upd_status: initialData.order_status,
                order_upd_notes: '',
            });
            setShowEditModal(true);
            setDetailsLoading(false);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    const handleUpdateOrders = async (values: any, actions: FormikValues) => {
        try {
            setLoading(true)
            const ordersData = new FormData();
            const { data: auth } = await orders_update(
                ordersData,
                values.order_upd_id,
                values.order_upd_status,
                values.order_upd_notes,
                userid
            )
            actions.resetForm();
            setShowEditModal(false)
            setTimeout(() => {
                setLoading(false)
                fetchProductsData("0");
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Submitted',
                    showConfirmButton: true,
                    timer: 3000
                });
            }, 500);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    }

    const itemsPerPage = 10;
    const totalItems = filteredMembers.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentMembers = filteredMembers.slice(startIndex, startIndex + itemsPerPage);
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const renderPagination = () => {
        if (totalPages <= 1) {
            return null; // Don't render pagination if there's only one page
        }

        const firstPage = Math.max(1, currentPage - 1);
        const lastPage = Math.min(totalPages, currentPage + 1);

        return (
            <ul className="pagination justify-content-end">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
                </li>
                {firstPage > 1 && (
                    <>
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(1)}>1</button>
                        </li>
                        {firstPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </>
                )}
                {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => firstPage + index).map((page) => (
                    <li className={`page-item ${page === currentPage ? 'active' : ''}`} key={page}>
                        <button className="page-link" onClick={() => handlePageChange(page)}>{page}</button>
                    </li>
                ))}
                {lastPage < totalPages && (
                    <>
                        {lastPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                        <li className="page-item">
                            <button className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
                </li>
            </ul>
        );
    };

    useEffect(() => {
        const filtered = orders?.filter((item: any) => {
            const fullName = `${item.order_id} ${item.contact_person}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
        });
        setFilteredMembers(filtered);
    }, [searchQuery, orders]);

    const printRef = useRef<HTMLDivElement | null>(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const downloadPDF = () => {
        const pdfContent = printRef?.current?.outerHTML;

        if (pdfContent) {
          // Generate a Blob containing the PDF content
          const pdfBlob = new Blob([pdfContent], { type: 'application/pdf' });

          // Create an <a> element with a download attribute
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(pdfBlob);

          // Customize the filename here
          const fileName = 'custom_filename.pdf';
          downloadLink.download = fileName;

          // Append the link to the document, trigger the click event, and remove the link
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          console.error('Error generating PDF content');
        }
      };

    const handleFilterChange = (e: any) => {
        setFilterStatus(e.target.value);
    };

    useEffect(() => {
        // Filter the orders based on the selected status
        const filteredOrders = orders.filter((item) => {
            if (!filterStatus) {
                return true;
            }
            return item.order_status === filterStatus;
        });

        setFilteredMembers(filteredOrders);
    }, [filterStatus, orders]);

    return (
        <>
            <div className='card'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Orders</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>Total {orders.length} orders</span>
                    </h3>
                    <div className='card-toolbar'>
                        <div>
                            <select
                                className='form-select form-select-solid'
                                onChange={handleFilterChange}
                                value={filterStatus}
                            >
                                <option value="">Order Status</option>
                                <option value="0">Pending</option>
                                <option value="1">Processing</option>
                                <option value="2">Rejected</option>
                                <option value="3">Completed</option>
                                <option value="4">Out For Delivery</option>
                                <option value="5">Getting Ready</option>
                                <option value="6">Order is Ready</option>
                            </select>
                        </div>
                        <div className="d-flex align-items-center position-relative ms-5">
                            <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4"><span className="path1"></span><span className="path2"></span></i>
                            <input type="text"
                                onChange={(e) => setSearchQuery(e.target.value)}
                                data-kt-ecommerce-product-filter="search"
                                className="form-control form-control-solid w-250px ps-12"
                                value={searchQuery}
                                placeholder="Search Order Id"
                            />
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    {isLoading ? (
                        <Loader/>
                    ) : (currentMembers.length > 0 ? (
                        <div className='table-responsive'>
                            <table className='table align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold fs-6 bg-light'>
                                        <th className='ps-4 rounded-start text-center'>Order Id</th>
                                        {/* <th > Mode</th> */}
                                        <th > Status</th>
                                        <th > Name</th>
                                        <th > Number</th>
                                        <th >Type</th>
                                        <th >Expected On</th>
                                        {/* <th >Expected Time</th> */}
                                        <th >Delivered On</th>
                                        <th >Payment</th>
                                        <th > Total</th>
                                        <th >Order On</th>
                                        <th className=' pe-4 rounded-end'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentMembers.map((item, index) => (
                                        <tr key={index}>
                                            <td className='text-dark fw-bold text-hover-primary fs-6 ps-4'>
                                                {item.order_id}
                                            </td>
                                            {/* <td className='text-dark fw-bold text-hover-primary fs-6 ps-4'>
                                            {item.order_mode === "0" ? (
                                                    <span className='badge bg-primary text-white'>Website</span>
                                                ) :(
                                                    <span className='badge bg-success text-white'>Whatsapp</span>
                                                )}
                                            </td> */}
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                {item.order_status === "1" ? (
                                                    <span className='badge bg-warning text-white'>Processing</span>
                                                ) : item.order_status === "0" ? (
                                                    <span className='badge bg-danger text-white'>Pending</span>
                                                ) : item.order_status === "3" ? (
                                                    <span className="badge bg-success text-white">Completed</span>
                                                ) : item.order_status === "2" ? (
                                                    <span className='badge bg-info text-white'>Rejected</span>
                                                ) : item.order_status === "4" ? (
                                                    <span className='badge bg-info text-white'>Out For Delivery</span>
                                                ) : item.order_status === "5" ? (
                                                    <span className='badge bg-info text-white'>Getting Ready</span>
                                                ) : item.order_status === "6" ? (
                                                    <span className='badge bg-info text-white'>Order is Ready</span>
                                                ) : null}
                                            </td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                {item.contact_person ? item.contact_person : "-"}
                                            </td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                {item.contact_number}
                                            </td>
                                            <td className='text-dark fw-bold fs-6'>

                                            <span>
                                                {item?.delivery_type === 'pickup' || item?.delivery_type === 'Pickup' ? (
                                                    <span className='badge bg-info text-white fs-9 fw-bold'>
                                                        <KTIcon iconName='scooter' className='fs-3 me-1 text-white' />
                                                        Pickup
                                                    </span>
                                                ) : item?.delivery_type === 'delivery' || item?.delivery_type === 'Delivery' ? (
                                                    <span className='badge bg-primary text-white fs-9 fw-bold'>
                                                        <KTIcon iconName='delivery-time' className='fs-3 me-1 text-white' />
                                                        Home Delivery
                                                    </span>
                                                ) : (
                                                   '-'
                                                )}
                                            </span>
                                            </td>
                                            <td className='text-dark fw-bold text-hover-primary fs-7'>
                                                {item?.expected_date} <br />{item?.expected_time}
                                            </td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                {item?.order_completed_on ? item.order_completed_on : '-'}
                                            </td>
                                            <th > {item.order_payment_status === "1" ? (
                                            <span className='badge bg-success text-white fs-9 fw-bold'>Paid</span>
                                            ): (
                                            <span className='badge bg-danger text-white fs-9 fw-bold'>Not Paid</span>
                                            )}</th>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                ₹{item.order_total}
                                            </td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                {item.order_on}
                                            </td>
                                            <td style={{ paddingTop: "10px" }}>
                                                <div className="dropdown">
                                                    <button className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="bi bi-three-dots fs-5"></i>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                        <li>
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={() => handleViewOrders(item.order_id,item.order_mode)}
                                                            >
                                                                View Order
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                            {renderPagination()}
                        </div>
                    ) :
                        <div className="d-flex justify-content-center p-10">
                            <div className="car">
                                <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                                <h3 className="text-center">No orders found</h3>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {showEditModal && (
                <div className="modal" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Orders Details :</h3>
                                <button onClick={handlePrint} className="print-button print_top_btn">
                                    Print
                                </button>

                                <button type="button" className="btn-close" onClick={() => setShowEditModal(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h6 className='fw-bold text-muted'>Order Id : <span className='fw-bold fs-6 text-dark'>{viewPerson?.order_id}</span></h6>
                                        <h6 className='fw-bold text-muted'>Customer Name : <span className='fw-bold fs-6 text-dark'>{viewPerson?.contact_person}</span></h6>
                                        <h6 className='fw-bold text-muted'>Customer Number : <span className='fw-bold fs-6 text-dark'>{viewPerson?.contact_number}</span></h6>
                                        <h6 className='fw-bold text-muted'>Address : <br></br><span className='fw-bold fs-6 text-dark'>{viewPerson?.address}</span></h6>


                                        <h6 className='fw-bold text-muted'>Total Items : <span className='fw-bold fs-6 text-dark'>{viewPerson?.order_items?.length}</span></h6>
                                    </div>
                                    <div className='col-md-6'>
                                        <h6 className='fw-bold text-muted'>Order On : <span className='fw-bold fs-6 text-dark'>{viewPerson?.order_on}</span></h6>
                                        <h6 className='fw-bold text-muted'>Order Status : <span className='fw-bold fs-6 text-dark'>
                                            {viewPerson?.order_status === "0" ? (
                                                <span className='badge bg-danger text-white'>Pending</span>
                                            ) : viewPerson?.order_status === "1" ? (
                                                <span className='badge bg-warning text-white'>Processing</span>
                                            ) : viewPerson?.order_status === "3" ? (
                                                <span className="badge bg-success text-white">Completed</span>
                                            ) : viewPerson?.order_status === "2" ? (
                                                <span className='badge bg-info text-white'>Rejected</span>
                                            ) : viewPerson?.order_status === "4" ? (
                                                <span className='badge bg-info text-white'>Out for delivery</span>
                                            ) :  viewPerson?.order_status === "5" ? (
                                                <span className='badge bg-info text-white'>Getting Ready</span>
                                            ) : viewPerson?.order_status === "6" ? (
                                                <span className='badge bg-info text-white'>Order is Ready</span>
                                            ) : null}</span>
                                        </h6>
                                        <h6 className='fw-bold text-muted'>Order Total : <span className='fw-bold fs-6 text-dark'>{viewPerson?.order_total}</span></h6>
                                        <h6 className='fw-bold text-muted'>GST: <span className='fw-bold fs-6 text-dark'>{viewPerson?.gst}</span></h6>
                                        <h6 className='fw-bold text-muted'>Delivery Type :
                                            <span>
                                                {viewPerson?.delivery_type === 'pickup' || viewPerson?.delivery_type === 'Pickup' ? (
                                                    <span className='badge bg-info text-white fs-7 fw-bold'>
                                                        <KTIcon iconName='scooter' className='fs-3 me-1 text-white' />
                                                        Pickup
                                                    </span>
                                                ) : viewPerson?.delivery_type === 'delivery' || viewPerson?.delivery_type === 'Delivery' ? (
                                                    <span className='badge bg-primary text-white fs-7 fw-bold'>
                                                        <KTIcon iconName='delivery-time' className='fs-3 me-1 text-white' />
                                                        Home Delivery
                                                    </span>
                                                ) : (
                                                    <span className='badge bg-secondary text-white fs-7 fw-bold'>
                                                        {/* Add your icon and text for the empty state */}
                                                        <KTIcon iconName='your-empty-state-icon' className='fs-3 me-1 text-white' />
                                                        Empty State
                                                    </span>
                                                )}
                                            </span>

                                        </h6>
                                        <h6 className='fw-bold text-muted'>Expected Date : <span className='fw-bold fs-6 text-dark'>{viewPerson?.expected_date}</span></h6>
                                        <h6 className='fw-bold text-muted'>Delivery Date : <span className='fw-bold fs-6 text-dark'>{viewPerson?.order_completed_on ? viewPerson.order_completed_on : '-'}</span></h6>
                                        <h6 className='fw-bold text-muted'>Delivery By : <span className='fw-bold fs-6 text-dark'> {viewPerson?.order_completed_by ? viewPerson.order_completed_by : '-'}</span></h6>
                                    </div>
                                </div>
                                <h6 className='fw-bold text-muted'>Notes : <span className='fw-bold fs-6 text-dark'>{viewPerson?.special_instructions}</span></h6>
                                {detailsLoading ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (viewPerson?.order_items && viewPerson.order_items.length > 0 ? (
                                    <div className='table-responsive'>
                                        <table className='table align-middle gs-0 gy-4 mt-3'>
                                            <thead>
                                                <tr className='fw-bold text-muted bg-light'>
                                                    <th className='ps-4 min-w-250px'>Item Name</th>
                                                    <th className='text-center min-w-150px'>Qty</th>
                                                    <th className='min-w-90px'>Price</th>
                                                    <th className='min-w-100px'>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {viewPerson?.order_items.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {item.order_item_name}
                                                        </td>
                                                        <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>
                                                            {item.order_item_qty}
                                                        </td>
                                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {item.order_item_price}
                                                        </td>
                                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {item.order_item_inline_total}
                                                        </td>
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                ) :
                                    <div className="d-flex justify-content-center p-10">
                                        <div className="car">
                                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' />
                                            <h3 className="text-center">No orders found</h3>
                                        </div>
                                    </div>

                                )}
                                <Formik initialValues={initialEditValues} onSubmit={handleUpdateOrders}>
                                    {() => (
                                        <Form>
                                            <div className="form-group">
                                                <Field type="hidden" name="order_upd_id" className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label mt-3 mb-3" htmlFor="order_upd_status">Status:</label>

                                                <Field
                                                    as="select"
                                                    name="order_upd_status"
                                                    aria-label="Select example"
                                                    className="form-select"
                                                >
                                                    <option value='0'>Order Pending</option>
                                                    <option value='1'>Order Processing</option>
                                                    <option value='2'>Order Rejected</option>
                                                    <option value='5'>Getting Ready</option>

                                                    {viewPerson?.delivery_type === 'delivery' || viewPerson?.delivery_type === 'Delivery' ? (
                                                        <>
                                                    <option value='4'>Out for Delivery</option>
                                                    </>
                                                    ):(<>
                                                    <option value='6'>Order is Ready</option>
                                                    </>)}

                                                  <option value='3'>Order Delivered & Completed</option>
                                                </Field>
                                            </div>
                                            <label className='form-label mt-3 mb-3'>Admin Notes :</label>
                                            <Field
                                                as='textarea'
                                                name="order_upd_notes"
                                                className="form-control"
                                                rows={3}
                                            />
                                            <div className='d-flex justify-content-end mt-5'>
                                                <button type="button" className="btn btn-secondary me-2" onClick={() => setShowEditModal(false)} disabled={loading}>
                                                    Cancel
                                                </button>
                                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                                    {!loading && 'Submit'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div >
            )}

            <div ref={printRef} className="my-custom-div">
                <div className='print_details'>
                    <div>
                        <img src={toAbsoluteUrl('/media/logos/swastik_main_logo.png')} alt="logo"  />
                    </div>
                    <div className='vendor_address'>
                        <h4>SWASTHIK ELECTRONICS</h4>
                        <p className='fw-bold'>No.122, Kamarajar Salai</p>
                        <p className='fw-bold'>Pondicherry - 605 013.India</p>
                        <p className='fw-bold'>+91 90871 89000</p>
                        <p className='fw-bold'>GST Number : 34ARPPM1668N1Z3</p>
                    </div>
                </div>
                <div className='print_details'>
                    <div>
                        <h4 className="invoice-title">INVOICE</h4>
                        <h6 className='mt-2'>Address:</h6>
                        <h6 className='fw-bold'><span className='fw-bold fs-6 text-dark'>{viewPerson?.contact_person}</span></h6>
                        <h6 className='fw-bold'>{viewPerson?.address}</h6>
                        <h6 className='fw-bold'><span className='fw-bold fs-6 text-dark'>{viewPerson?.contact_number}</span></h6>
                        <h3 className="total_items badge bg-warning">
                            Total Items: {viewPerson?.order_items?.length}
                        </h3>
                    </div>
                    <div className='vendor_address'>
                        <p className='fw-bold invoice-title'>Order Id :  {viewPerson?.order_id} </p>
                        <p className='fw-bold text-dark'>Order On :  {viewPerson?.order_on} </p>
                        <p className='fw-bold text-dark'>Order Status :
                            {viewPerson?.order_status === "0" ? (
                                <span className='badge bg-danger text-white ms-2'>Pending</span>
                            ) : viewPerson?.order_status === "1" ? (
                                <span className='badge bg-warning text-white ms-2'>Processing</span>
                            ) : viewPerson?.order_status === "3" ? (
                                <span className="badge bg-success text-white ms-2">Completed</span>
                            ) : viewPerson?.order_status === "2" ? (
                                <span className='badge bg-info text-white ms-2'>Rejected</span>
                            ) : null}
                        </p>
                        <p className='fw-bold text-dark'>Delivery Type :
                        <span>
                                                {viewPerson?.delivery_type === 'pickup' || viewPerson?.delivery_type === 'Pickup' ? (
                                                    <span className='fw-bold ms-2'>
                                                         Pickup
                                                    </span>
                                                ) : viewPerson?.delivery_type === 'delivery' || viewPerson?.delivery_type === 'Delivery' ? (
                                                    <span className='fw-bold ms-2 fs-7'>
                                                        Home Delivery
                                                    </span>
                                                ) : ('')}
                                            </span>
                                            </p>
                                            <p className='fw-bold text-muted'>Expected Date : <span className='fw-bold fs-7 text-dark'>{viewPerson?.expected_date}</span></p>

                    </div>
                </div>
                <div className='table-responsive'>
                    <table className='table align-middle gs-0 gy-4 mt-3'>
                        <thead>
                            <tr className='fw-bold bg-dark print_table_color'>
                                <th className='ps-4 min-w-250px'>Item Name</th>
                                <th className='text-center min-w-200px'>Qty</th>
                                <th className='min-w-100px'>Price</th>
                                <th className='min-w-120px pe-4'>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewPerson?.order_items.map((item, index) => (
                                <tr key={index}>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {item.order_item_name}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6 text-center'>
                                        {item.order_item_qty}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {item.order_item_price}
                                    </td>
                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                        {item.order_item_inline_total}
                                    </td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                    <hr />
                    <br />
                    <h6 className='fw-bold text-muted align-right'>GST: {viewPerson?.gst}</h6>

                    <h2 className='fw-bold text-muted align-right'>Total : {viewPerson?.order_total}</h2>

                </div>
                <h6 className='fw-bold text-muted'>Notes : <span className='fw-bold fs-6 text-dark'>{viewPerson?.special_instructions}</span></h6>
            </div>
        </>
    )
}

export default Orders