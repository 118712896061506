/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getUserByToken, registerPassword } from '../core/_requests'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useNavigate } from "react-router-dom";





const initialValues = {
  password: '',
  changepassword: '',
}


const registrationSchema = Yup.object().shape({

  password: Yup.string()
    .min(8, 'Minimum 8 Characters Must')
    .max(50, 'Maximum 50 Characters Allowed')
    .required('Password is required'),
  changepassword: Yup.string()
    .min(8, 'Minimum 8 Characters Must')
    .max(50, 'Maximum 50 Characters Allowed')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

export function Password() {
  const [loading, setLoading] = useState(false)
  const [user_phone, setuserphone] = useState(localStorage.getItem('user_mobile')!);
  const { saveAuth, setCurrentUser } = useAuth()
  const [formstatus, setformStatus] = useState('alert-danger');
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await registerPassword(

          user_phone,
          values.password

        )
        //console.log(auth);
        saveAuth(auth)
        const { data: user } = await getUserByToken(auth.api_token)
        setformStatus('alert-succes');
        setStatus('Successfully Registered')
        setCurrentUser(user)
        // setTimeout(() => {

        //   //localStorage.removeItem("lc_useremail");
        // }, 1500)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])


  return (
    <>
      <div className='row g-5 g-xl-8 me-0'>
        <div className='col-xl-7 login_left_image'>
          <div className='d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100'>
            <img src={toAbsoluteUrl('/media/auth/agency.png')} className='mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20' alt='Metronic' />
            <h1 className='text-gray-800 fs-2qx fw-bold text-center mb-7'>Fast, Efficient and Productive</h1>
            <p className='text-gray-600 fs-base text-center fw-semibold login_paragraph'>In this kind of post, the blogger introduces a person they’ve interviewed
              and provides some background information about the interviewee and their
              work following this is a transcript of the interview.</p>
          </div>
        </div>
        <div className='col-xl-5'>
          <div className="d-flex flex-column flex-center w-md-600px p-10 login">
            <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px pt-10 pb-10">
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <div className='text-center mb-11'>
                  <h1 className='text-dark fw-bolder'>Setup New Password</h1>
                </div>
                {formik.status && (
                  <div className={`mb-lg-15 alert ${formstatus}`}>
                    <div className='alert-text font-weight-bold'><span className="fa-regular fa-circle-check"></span> {formik.status}</div>
                  </div>
                )}

                {/* begin::Form group Password */}
                <div className='fv-row mb-8' data-kt-password-meter='true'>
                  <div className='mb-1'>
                    <label className='form-label fw-bolder text-dark fs-6'>Create Password</label>
                    <div className='position-relative mb-3'>
                      <input
                        type='password'
                        placeholder='Password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.password && formik.errors.password,
                          },
                          {
                            'is-valid': formik.touched.password && !formik.errors.password,
                          }
                        )}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className='d-flex align-items-center mb-3'
                      data-kt-password-meter-control='highlight'
                    >
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div>

                  </div>
                  <div className='text-muted'>
                    Use 8 or more characters with a mix of letters, numbers & symbols.
                  </div>
                </div>
                {/* end::Form group */}

                {/* begin::Form group Confirm password */}
                <div className='fv-row mb-5'>
                  <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                  <input
                    type='password'
                    placeholder='Password confirmation'
                    autoComplete='off'
                    {...formik.getFieldProps('changepassword')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                      },
                      {
                        'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                      }
                    )}
                  />
                  {formik.touched.changepassword && formik.errors.changepassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.changepassword}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='text-center'>
                  <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
