import { FC,useState,useEffect } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App' 
import { UserDeatils_Post } from '../modules/auth/core/_requests'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

interface UserDetails {
  company_name: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  pic: string;
  zipcode: string;
  email: string;
  address_line1: string;
  address_line2: string;
}

const AppRoutes: FC = () => {

  const { currentUser } = useAuth()
  const UserDetails = localStorage.getItem('user-details')
  const [user_number] = useState(currentUser?.phone || '')
  const [details, setDetails] = useState<UserDetails>()
  let apiToken: any = null;

  if (UserDetails) {
    const userDetailsObject = JSON.parse(UserDetails);
    if (userDetailsObject.api_token) {
      apiToken = userDetailsObject.api_token;
    }
  }

  useEffect(() => {
    UserDeatils_Post(apiToken, user_number)
      .then(response => {
        setDetails(response.data);
      })
      .catch(error => {
        console.error('API Error:', error);
      });
  }, [apiToken]);

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route  element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
          </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
