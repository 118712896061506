import React, { useEffect, useState } from 'react'
import { Form, Formik, FormikValues, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import { Wp_config_post_data, Addons, Wp_setting_post_data, Wp_config_upd_data } from '../modules/auth/core/_requests'
import Swal from 'sweetalert2';
import { KTIcon } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'


interface SMSdata {
    addon_id: string;
    addon_type: string;
    addon_password: string;
    wp_phone_number_id: string;
    wp_business_account_id: string;
    access_token: string;
    addon_created_on: string;
    addon_status: string;
}

const WhatsappConfigure = () => {
    const addon_type = "whatsapp"
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false); 
    const [addons, setAddons] = useState<SMSdata[]>([]); 
    const [initialEditValues, setInitialEditValues] = useState(
        {
            addon_id: '',
            wp_phone_number_id_upd: '',
            wp_business_account_id_upd: '',
            wp_number_upd: '',
            access_token_upd: '',
        }
    )

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const initialValues = {
        wp_phone_number_id: '',
        wp_business_account_id: '',
        access_token: '',
        wp_number: '',
    }

    const validationSchema = Yup.object().shape({
        wp_phone_number_id: Yup.string().required('Phone number ID is required'),
        wp_business_account_id: Yup.string().required('WhatsApp Business Account ID is required'),
        access_token: Yup.string().required('Access Token id is required'),
        // wp_number: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits').required('Whatsapp Number is required'),
        wp_number: Yup.string().required('Whatsapp Number is required'),
    });

    const handleSubmit = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const { data: auth } = await Wp_config_post_data(
                values.wp_phone_number_id,
                values.wp_business_account_id,
                values.access_token,
                values.wp_number,
                addon_type,
            );
            actions.resetForm();
            ADDON_GET("whatsapp");
            setTimeout(() => {
                setLoading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Submitted',
                    showConfirmButton: true,
                    timer: 3000
                });
            }, 500);
            setShowModal(false)
        }
        catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    const ADDON_GET = async (addon: any) => { 
        try {
            const response = await Addons(addon);
            setAddons(response.data.addon_details);
            if (response.data.addon_details) {
                setIsLoading(false);
                const initialData = response.data.addon_details
                const addonMetaData = JSON.parse(initialData[0].addon_meta_data); 
                setInitialEditValues({
                    addon_id: initialData.length > 0 ? initialData[0].addon_id : '',
                    wp_phone_number_id_upd: addonMetaData.wp_phone_number_id,
                    wp_business_account_id_upd: addonMetaData.wp_business_account_id,
                    access_token_upd: addonMetaData.access_token,
                    wp_number_upd: addonMetaData.wp_number,
                }) 
            } 
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    useEffect(() => {
        ADDON_GET("whatsapp");
    }, []);

    const handleSubmitUpdate = async (values: any, actions: FormikValues) => {
        setLoading(true)
        try {
            const { data: auth } = await Wp_config_upd_data(
                values.addon_id,
                values.wp_phone_number_id_upd,
                values.wp_business_account_id_upd,
                values.access_token_upd,
                values.wp_number_upd,
                addon_type,
            );
            actions.resetForm();
            ADDON_GET("whatsapp");
            setTimeout(() => {
                setLoading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Updated',
                    showConfirmButton: true,
                    timer: 3000
                });
            }, 500);
            setShowModal(false)
        }
        catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    const initialValuesTo = {
        to_whatsapp_number: '',
        message: '',
    }

    const validationSchemaTo = Yup.object().shape({
        to_whatsapp_number: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits').required('To Whatsapp Number is required'),
        message: Yup.string().required('Message is required'),
    });

    const handleSubmitTo = (values: any) => {
        console.log(values)
    }

    const initialSettingsValues = {
        admin_wp_number: '',
        admin_wp_notication: '',
    }

    const validationSettings = Yup.object().shape({
        admin_wp_number: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits').required('Admin Whatsapp Number is required'),
        admin_wp_notication: Yup.array().required('Notification is required').test('at-least-one-setting', 'Select at least one Notification', (value) => value && value.length > 0),
    });

    const handleSubmitSettings = async (values: any) => {
        console.log(values)
        setLoading(true)
        try {
            const postData = new FormData();
            postData.append('admin_wp_notication', JSON.stringify(values.admin_wp_notication));
            const { data: auth } = await Wp_setting_post_data(
                postData,
                values.admin_wp_number,
            ); 
            setTimeout(() => {
                setLoading(false)
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Successfully Submitted',
                    showConfirmButton: true,
                    timer: 3000
                });
            }, 500);
        }
        catch (error) {
            console.error('Error submitting form data:', error);
        }
    }

    return (
        <>
        <Link to='/settings/api'>
                <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                </button>
            </Link>
            <div className="row">
                <div className='col-md-8 offset-md-2 card p-8'>
                    <h4 className='mb-5'>Whatsapp Configuration</h4>
                    <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_4"
                            >
                                Configuration
                            </a>
                        </li>

                        {/* <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_5"
                            >
                                Test
                            </a>
                        </li>

                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#kt_tab_pane_6"
                            >
                                Settings
                            </a>
                        </li> */}
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="kt_tab_pane_4" role="tabpanel">
                            {isLoading ? (
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div> 
                            ) : (addons.length > 0 ? (
                                <>
                                    {showModal ? (
                                        <div>
                                            <Formik initialValues={initialEditValues} onSubmit={handleSubmitUpdate}>
                                                {() => (
                                                    <Form>
                                                        <div className="form-group">
                                                            <Field type="hidden" name="addon_id" className="form-control" />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="wp_phone_number_id_upd" className='form-label mt-3 mb-3'>Phone number ID</label>
                                                            <Field type="text" name="wp_phone_number_id_upd" className="form-control" />
                                                            <ErrorMessage name="wp_phone_number_id_upd" component="div" className="text-danger" />
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="form-label mt-3 mb-3" htmlFor="wp_business_account_id_upd" >WhatsApp Business Account ID</label>
                                                            <Field type="text" name="wp_business_account_id_upd" className="form-control" />
                                                            <ErrorMessage name="wp_business_account_id_upd" component="div" className="text-danger" />
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="form-label mt-3 mb-3" htmlFor="access_token_upd" >Access token</label>
                                                            <Field type="text" name="access_token_upd" className="form-control" />
                                                            <ErrorMessage name="access_token_upd" component="div" className="text-danger" />
                                                        </div>

                                                        <div className="form-group">
                                                            <label className="form-label mt-3 mb-3" htmlFor="wp_number_upd" >Whatsapp Number</label>
                                                            <Field type="text" name="wp_number_upd" className="form-control" />
                                                            <ErrorMessage name="wp_number_upd" component="div" className="text-danger" />
                                                        </div>

                                                        <div className='d-flex justify-content-end mt-5'>
                                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                                {!loading && 'Submit'}
                                                                {loading && (
                                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                                        Please wait...{' '}
                                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                                    </span>
                                                                )}
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    ) : (
                                        <>
                                            <div className='d-flex justify-content-between align-items-center mt-10'>
                                                <div className="mb-10">
                                                    <h5 className='fw-bolder'>Configuration Details</h5>
                                                </div>
                                                <div>
                                                    <button className='btn btn-sm btn-primary' onClick={handleButtonClick}>
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='row mb-7'>
                                                <label className='col-lg-4 fw-bold text-muted'>Phone number ID</label>
                                                <div className='col-lg-8'>
                                                    <span className='fw-bolder fs-6 text-dark'>{initialEditValues.wp_phone_number_id_upd}</span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-4 fw-bold text-muted">WhatsApp Business Account ID</label>
                                                <div className="col-lg-8">
                                                    <div className="password-field">
                                                        <span className="fw-bolder fs-6 text-dark me-5">
                                                            {initialEditValues.wp_business_account_id_upd}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-7'>
                                                <label className='col-lg-4 fw-bold text-muted'>Access token</label>
                                                <div className='col-lg-8'>
                                                    <span className='fw-bolder fs-6 text-dark'>{initialEditValues.access_token_upd}</span>
                                                </div>
                                            </div>
                                            <div className='row mb-7'>
                                                <label className='col-lg-4 fw-bold text-muted'>Whatsapp Number</label>
                                                <div className='col-lg-8'>
                                                    <span className='fw-bolder fs-6 text-dark'>{initialEditValues.wp_number_upd}</span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) :
                                <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
                                    {() => (
                                        <Form>
                                            <div className="form-group">
                                                <label htmlFor="wp_phone_number_id" className='form-label mt-3 mb-3'>Phone number ID</label>
                                                <Field type="text" name="wp_phone_number_id" className="form-control" />
                                                <ErrorMessage name="wp_phone_number_id" component="div" className="text-danger" />
                                            </div>

                                            <div className="form-group">
                                                <label className="form-label mt-3 mb-3" htmlFor="wp_business_account_id" >WhatsApp Business Account ID</label>
                                                <Field type="text" name="wp_business_account_id" className="form-control" />
                                                <ErrorMessage name="wp_business_account_id" component="div" className="text-danger" />
                                            </div>

                                            <div className="form-group">
                                                <label className="form-label mt-3 mb-3" htmlFor="access_token" >Access token</label>
                                                <Field type="text" name="access_token" className="form-control" />
                                                <ErrorMessage name="access_token" component="div" className="text-danger" />
                                            </div>

                                            <div className="form-group">
                                                <label className="form-label mt-3 mb-3" htmlFor="wp_number" >Whatsapp Number</label>
                                                <Field type="text" name="wp_number" className="form-control" />
                                                <ErrorMessage name="wp_number" component="div" className="text-danger" />
                                            </div>

                                            <div className='d-flex justify-content-end mt-5'>
                                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                                    {!loading && 'Submit'}
                                                    {loading && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...{' '}
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </div>

                        <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                            <Formik validationSchema={validationSchemaTo} initialValues={initialValuesTo} onSubmit={handleSubmitTo}>
                                {() => (
                                    <Form>
                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="to_whatsapp_number" >To Whatsapp Number</label>
                                            <Field type="text" name="to_whatsapp_number" className="form-control" />
                                            <ErrorMessage name="to_whatsapp_number" component="div" className="text-danger" />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="message" >Message</label>
                                            <Field type="text" name="message" as="textarea" row={5} className="form-control" />
                                            <ErrorMessage name="message" component="div" className="text-danger" />
                                        </div>

                                        <div className='d-flex justify-content-end mt-5'>
                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                {!loading && 'Submit'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className="tab-pane fade" id="kt_tab_pane_6" role="tabpanel">
                            <Formik validationSchema={validationSettings} initialValues={initialSettingsValues} onSubmit={handleSubmitSettings}>
                                {() => (
                                    <Form>
                                        <div className="form-group">
                                            <label className="form-label mt-3 mb-3" htmlFor="admin_wp_number" >Admin Whatsapp Number</label>
                                            <Field type="text" name="admin_wp_number" className="form-control" />
                                            <ErrorMessage name="admin_wp_number" component="div" className="text-danger" />
                                        </div>

                                        <label className="form-label mt-3 mb-3">Notifications</label>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field type="checkbox" name="admin_wp_notication" value="New Orders" className="form-check-input" id="New_orders" />
                                            <label className="form-check-label" htmlFor="New_orders">New Orders</label>
                                        </div>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field type="checkbox" name="admin_wp_notication" value="Completed Order" className="form-check-input" id="Completed_order" />
                                            <label className="form-check-label" htmlFor="Completed_order">Completed Order</label>
                                        </div>
                                        <div className="form-check form-check-custom form-check-solid mb-3">
                                            <Field type="checkbox" name="admin_wp_notication" value="Pending Order" className="form-check-input" id="pending_order" />
                                            <label className="form-check-label" htmlFor="pending_order">Pending Order</label>
                                        </div>
                                        <ErrorMessage name="admin_wp_notication" component="div" className="text-danger" />
                                        <div className='d-flex justify-content-end mt-5'>
                                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                                {!loading && 'Submit'}
                                                {loading && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...{' '}
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhatsappConfigure