/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Otp } from './components/Otp'
import { Login } from './components/Login' 
import { Password } from './components/Password'
import { Privacypolicy } from './components/Privacypolicy'
import { Resetpassword } from './components/Resetpassword'
import { ResetOtp } from './components/ResetOtp'
import OtpVerification from './components/OtpVerification' 
import SuccessPage from './components/SuccessPage'


const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (

    <div>
      <Outlet />
    </div>

  )
}

const AuthPage = () => (
  <Routes>
    <Route path='privacy_policy' element={<Privacypolicy />} />
    <Route path="success/:order_id" element={<SuccessPage />} />
    <Route element={<AuthLayout />}>
      <Route index element={<Login />} />
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='otp-verification' element={<OtpVerification />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<Resetpassword />} />
      <Route path='password' element={<Password />} />
      <Route path='otp' element={<Otp />} />
      <Route path='reset-otp' element={<ResetOtp />} />  
    </Route>
  </Routes>
)

export { AuthPage }
